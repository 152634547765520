.select {
  // Select Label
  label {
    color: color(neutral);
    display: block;
    margin-bottom: space(xxs);
  }

  &--hidden-label label {
    @include hide-visually();
  }

  // Select Wrapper
  &__wrapper {
    position: relative;
  }

  // Native Select
  select {
    @include typography(copy);

    appearance: none;
    background: color(neutral, 0);
    border: 2px solid color(neutral, 70);
    color: color(neutral);
    outline: none;
    padding: space(xxs) space(xs);
    padding-right: space(m);
    transition: color 200ms, background-color 200ms, border-color 200ms;
    width: 100%;

    option {
      color: color(neutral);
    }

    &::-ms-expand {
      display: none;
    }
  }

  &.form-error select {
    border-color: color(warning);
  }

  select:hover {
    border-color: color(neutral, 80);
  }

  select:disabled {
    border-color: color(neutral, 50);
  }

  [data-whatinput='keyboard'] & select:focus {
    border-color: color(interaction);
  }

  // &--success select {
  //   border-color: color(success);
  // }

  // Custom Select Icon
  svg {
    fill: color(neutral, 70);
    pointer-events: none;
    position: absolute;
    right: space('xs');
    top: 50%;
    transform: translateY(-50%);
  }

  // Error Message
  &__error.text {
    color: color(warning);
    display: none;
    margin-top: space(xxs);
  }

  &.form-error &__error {
    display: block;
  }

  // Hint Message
  &__hint.text {
    color: color(neutral, 80);
    display: block;
    margin-top: space('xxs');
  }
}
