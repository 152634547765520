@import './colors';
@import './typography';
@import '../stylesheets/tools/color';
@import '../stylesheets/tools/typography';
@import '../stylesheets/tools/css-variables';
@import '../stylesheets/tools/adaptive-size';

.theme-color-standard {
  @include colors-to-variables(get-color-theme('standard'));
}

.theme-color-executive {
  @include colors-to-variables(get-color-theme('executive'));
}

.theme-type-default {
  @each $name, $value in get-typography-theme('default') {
    // Loop over typography keys and build css variables
    @include adaptive-size-css-variables(#{$name}, $value);
  }
}

.theme-type-serif {
  @each $name, $value in get-typography-theme('serif') {
    // Loop over typography keys and build css variables
    @include adaptive-size-css-variables(#{$name}, $value);
  }
}
