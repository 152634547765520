.person {
  &__wrapper {
    @include max-width-container;
    @include get-grid;
  }

  &__header {
    grid-column: 1 / span 4;
    text-align: center;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  &__lead-in.text {
    color: color(neutral, 80);
    margin-top: space(xs);
  }

  &__ul {
    row-gap: space(xxs);
    @include get-grid((), false);

    @include mq(s) {
      grid-column: 1 / span 6;
    }
  }

  &__content {
    grid-column: 1 / span 4;
    margin-top: space(m);

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 1 / span 12;
      // margin-top: space(l);
    }
  }

  &__item {
    display: flex;
    grid-column: span 4;
    padding: space(s) space(xs);
    position: relative;
    z-index: 1;

    @include mq(s) {
      align-items: center;
      display: flex;
      flex-direction: column;
      grid-column: span 2;
      height: 100%;
    }

    @include mq(m) {
      grid-column: span 3;
      padding: space(l) space(s);
    }
  }

  &__item-image-wrapper {
    position: relative;
    flex-shrink: 0;
    height: 80px;
    margin-right: space(s);
    width: 80px;

    @include mq(s) {
      margin-bottom: space(s);
      margin-right: 0;
    }

    @include mq(m) {
      height: 150px;
      margin-bottom: space(m);
      width: 150px;
    }

    img {
      border-radius: 50%;
    }
  }

  &__info {
    @include mq(s) {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__item-label {
    @include mq(s) {
      text-align: center;
    }
  }

  &__item-title {
    padding-top: space(xs);

    @include mq(s) {
      text-align: center;
    }
  }

  &__item-link,
  &__checked {
    margin-top: auto;
    padding-top: space(xs);
  }

  &__item--rated {
    .person__item-image img {
      border: 3px solid color(success);
    }
  }

  &__checked {
    display: flex;
    align-items: center;
    color: color(success);
    margin-bottom: 2px;
  }

  &__item-checkmark {
    fill: color(success) !important;
  }
  // *************** Animation ***************
  // &[data-animatable-module] &__header {
  //   // inital animation style

  //   opacity: 0;
  //   position: relative;
  //   transform: translateY(40px);
  //   // animation transition

  //   transition: opacity bezier('highlight') time('xxs'),
  //     transform bezier('highlight') time('xxs');
  //   // in viewport style
  //   &.is-in-viewport {
  //     opacity: 1;
  //     transform: translateY(0);
  //   }
  // }

  // &[data-animatable-module] &__item {
  //   // inital style

  //   opacity: 0;
  //   position: relative;
  //   transform: translateY(20px);
  //   // animation transition

  //   transition: opacity bezier('entrance') time('m'),
  //     transform bezier('entrance') time('m');
  //   // in viewport style
  //   &.is-in-viewport {
  //     opacity: 1;
  //     transform: translateY(0);
  //   }
  // }
}
