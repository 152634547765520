.file-upload {
  // Input Label
  &__label.text {
    color: color(neutral);
    margin-bottom: space(xxs);
  }

  // Native Input
  input {
    @include hide-visually();
  }

  // Custom Input
  &__custom-input {
    background: color(neutral, 0);
    border: 2px solid color(neutral, 70);
    color: color(neutral);
    cursor: pointer;
    outline: none;
    padding: space(xxs) space(xs);
    position: relative;
    transition: color 200ms, background-color 200ms, border-color 200ms;
    width: 100%;

    // TODO add to html?
    @include typography(copy);

    &-placeholder {
      color: color(neutral, 70);
    }

    &-icon.icon {
      fill: color(neutral, 70);
      position: absolute;
      right: space(xs);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__custom-input:hover {
    border-color: color(neutral, 80);
  }

  input:disabled ~ &__custom-input {
    border-color: color(neutral, 50);
  }

  input:disabled ~ &__custom-input &__custom-input-placeholder {
    color: color(neutral, 50);
  }

  &.form-error &__custom-input,
  &.form-error--uploads &__custom-input {
    border-color: color(warning);
  }

  input:focus-visible {
    border-color: color(interaction);
  }

  // &--success input {
  //   border-color: color(success);
  // }

  // Error Message
  &__error.text {
    color: color(warning);
    display: none;
    margin-top: space(xxs);
  }

  &.form-error--uploads &__error--global,
  &.form-error &__error--general {
    display: block;
  }

  // Hint Message
  &__hint.text {
    color: color(neutral, 80);
    display: block;
    margin-top: space('xxs');
  }
}
