// TODO use "has-grid" modifier for grid css
.contact {
  position: relative;
  z-index: get-z-index('contact');

  &--has-background {
    background-color: color(primary, 10);
  }

  &__wrapper {
    color: color(neutral);
    @include max-width-container;
    @include get-grid;

    @include mq(m) {
      margin-bottom: space(m);
    }
  }

  &--has-dialog &__wrapper {
    color: color(primary);

    @include mq(m) {
      margin-bottom: 0;
    }
  }

  &__content {
    grid-column: 1 / span 4;
    padding: 0 space(m);

    @include mq(s) {
      align-items: center;
      display: flex;
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
      padding: 0;
    }
  }

  &--has-background &__content {
    padding: space(l) space(m);

    @include mq(m) {
      padding: space(l) 0;
    }
  }

  &__content-image {
    height: 110px;
    margin-bottom: space(s);
    width: 110px;

    @include mq(s) {
      margin-bottom: 0;
      margin-right: space(l);
    }

    @include mq(m) {
      height: 200px;
      margin-right: space(xl);
      width: 200px;
    }

    img {
      border-radius: 50%;
    }
  }

  &__headline.text {
    margin-bottom: space(s);
  }

  &__name.text {
    margin-bottom: space(xs);
  }

  &__details {
    display: flex;
    flex-direction: column;
    margin-top: space(xs);

    a {
      @include mouseHoverState(0.8);
    }
  }

  &__detail {
    align-items: center;
    display: flex;

    &:not(:first-child) {
      margin-top: space(xs);
    }
  }

  &__detail-icon {
    margin-right: space(xs);
  }

  &__detail-link,
  &__detail-link:hover,
  &__detail-link:active,
  &__detail-link:focus {
    border-bottom-color: color(neutral, 0);
    color: color(neutral, 0);
    margin-top: space(s);
  }

  // &__role.text {
  // }
  &__ctas {
    margin-top: space(m);
  }

  &__form {
    padding: 0;
    margin-top: space(l);

    @include mq(s) {
      padding: 0;
    }

    @include mq(m) {
      margin-top: space(xl);
      padding: 0;
    }
  }
}

// *************** Print Styles ***************
@media print {
  .contact__cta {
    display: none;
  }
}
