.radio {
  // Radio Group Lael
  &__label {
    display: block;
    font-weight: bold;
    margin-bottom: space(xs);
  }

  &--hidden-label &__label {
    @include hide-visually();
  }

  // Radio Item
  &__item {
    align-items: center;
    cursor: pointer;
    display: flex;
    line-height: 1;
    position: relative;

    &:not(:last-child) {
      margin-bottom: space(s);
    }

    &--disabled {
      cursor: not-allowed;
    }
  }

  // Native Input
  input {
    @include hide-visually();
  }

  // Custom Input
  &__item-custom-icon {
    // margin-top: 2px; // to align with label
    background-color: color(neutral, 0);
    border: 2px solid color(neutral, 70);
    border-radius: 100%;
    display: inline-block;
    height: space(s);
    margin-right: space(xs);
    position: relative;
    transition: color 200ms, background-color 200ms, border-color 200ms;
    width: space(s);
    flex-shrink: 0;

    &:after {
      background: color(neutral, 100);
      border-radius: 100%;
      content: '';
      display: none;
      height: space(xs);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: space(xs);
    }
  }

  label:has(input:disabled) {
    cursor: not-allowed;
  }

  input:disabled ~ &__item-custom-icon {
    border-color: color(neutral, 50);

    &:after {
      opacity: 0.15;
    }
  }

  input:checked ~ &__item-custom-icon {
    &:after {
      display: block;
    }
  }

  &.form-error &__item-custom-icon {
    border-color: color(warning);
  }

  &__item:hover input:not([disabled]) ~ &__item-custom-icon {
    border-color: color(neutral, 80);
  }

  input:focus-visible ~ .radio__item-custom-icon {
    border-color: color(interaction);
  }

  // Radio Item Label
  // &__item-label {}
  input:disabled ~ &__item-label {
    color: color(neutral, 70);
  }

  // Error Message
  &__error.text {
    color: color(warning);
    display: none;
    margin-top: space(xxs);
  }

  &.form-error &__error {
    display: block;
  }

  // Hint Message
  &__hint.text {
    color: color(neutral, 80);
    display: block;
    margin-top: space('xxs');
  }

  &__rangeDescription.text {
    display: block;
    margin: space(xs) 0;
  }

  &--inline & {
    &__option-range {
      display: flex;
      flex-direction: column;

      @include mq(l) {
        flex-direction: row;

        & > .text:first-of-type {
          margin-top: -0.25em;
          padding: 0 space(l) 0 0;
        }

        & > .text:last-of-type {
          margin-top: -0.25em;
          padding: 0 0 0 space(l);
        }
      }
    }

    &__rangeDescription {
      flex-basis: 40%;
    }

    &__options {
      margin-top: space(xs);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: space(m);

      @include mq(m) {
        margin-top: 0;
        flex-basis: 100%;
        flex-direction: row;
      }
    }

    &__item {
      text-align: center;
      margin-bottom: space(xs);
      width: max-content;
    }

    &__item-label {
      margin-top: space(xxs);
    }
  }

  &--space-between .radio {
    &__options {
      justify-content: space-between;
    }
    &__item {
      flex: 1 1 0px;
    }
  }

  &--labels-column .radio {
    &__item {
      height: min-content;
      gap: space(xxs);
      @include mq(m) {
        flex-direction: column;
      }
    }

    &__item-custom-icon {
      margin: 0;
    }
  }
}
