.file-upload-jsx {
  &__wrapper {
    border: dashed 2px color(neutral, 70);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: space(xs);
    margin-bottom: space(xs);
    width: 100%;
    padding: space(s) space(s) space(xs) space(s);
    border-radius: 6px;
    transition: all 0.25s ease;

    @include mq(s) {
      padding: space(xl) space(l) space(l) space(l);
    }
  }

  input {
    display: none;
  }

  &__hint.text {
    color: color(neutral, 70);
    text-transform: uppercase;
  }

  &__cancel-btn {
    background: none;
    border: none;
    display: inline-block;
    width: min-content;
    padding: 0;
    margin: 0;
  }

  &__files {
    display: none;
  }

  &__cancel-btn {
    position: absolute;
    right: 0;
  }

  &__wrapper--has-uploads {
    border: 2px solid color(neutral, 70);
    padding: space(xs) space(m);
    align-items: flex-start;

    @include mq(s) {
      padding: space(xs) space(m);
    }

    .file-upload-jsx {
      &__dragndrop,
      &__button {
        display: none;
      }

      &__files {
        display: block;
      }
    }
  }

  &__wrapper--dragging {
    border: dashed 2px color(primary);
  }

  &__error.text {
    display: block;
    color: color(warning);
    margin-top: space(xxs);
  }
}
