.pdf-export {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: space('l');
  }

  &__title {
    justify-self: flex-end;
  }

  &__logo {
    height: auto;
    width: 80px;
  }
}
