.switch {
  margin-bottom: calc(var(--spacings-l));
  // z-index: get-z-index('switch');

  @include mq(m) {
    margin-bottom: calc(var(--spacings-xxl));
  }

  &__wrapper {
    position: relative;
  }

  &--is-intro &__wrapper {
    background-color: color(primary, 70);
  }

  /* ---------- Content ---------- */
  &__content {
    @include max-width-container;
    @include get-grid;

    aspect-ratio: 2.5/1; // min-height equals 16/10
    grid-template-rows: 1fr auto;
    position: relative;
  }

  /* ---------- Background ---------- */
  &__background {
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__background-inner {
    @include max-width-container('images');

    height: 100%;
    overflow: hidden;
    position: relative;

    &:after {
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 85%
      );
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &--light &__background-inner {
    &:after {
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0) 0%,
        rgba(235, 232, 233, 0.4) 85%
      );
    }
  }

  &__bg-image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  /* ---------- Main ---------- */
  &__main {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 4;
    justify-content: center;
    padding: space(xl) 0 space(xxl);

    @include mq(s) {
      grid-column: 1 / span 4;
    }

    @include mq(m) {
      grid-column: 1 / span 6;
      padding: space(xxl) 0 space(xl);
    }

    @include mq(l) {
      grid-column: 1 / span 5;
      padding-left: space(l);
    }
  }

  &--is-intro &__main {
    padding-bottom: calc(var(--spacings-xxl) + var(--spacings-l));

    @include mq(s) {
      padding-left: space(s);
    }

    @include mq(m) {
      grid-column: 1 / span 12;
      padding-bottom: calc(var(--spacings-xxl) + var(--spacings-m));
    }

    @include mq(l) {
      grid-column: 2 / span 10;
      padding-left: 0;
    }
  }

  &__headline.text {
    display: block;
    margin-top: space(xs);
    padding-bottom: space(m);
    position: relative;
  }

  /* ---------- Intro ---------- */
  &__intro {
    @include max-width-container;
    @include get-grid;
  }

  &__intro-content {
    color: color(primary, 50);
    grid-column: 1 / span 4;
    margin-top: space(l);
    padding: 0 space(s);
    text-align: center;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
      margin-top: space(xxl);
      padding: 0;
    }
  }

  &__intro &__headline.text {
    padding-bottom: 0;
  }

  /* ---------- Links ---------- */
  &__links {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 4;
    margin-bottom: space(l);

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      flex-direction: row;
      grid-column: 1 / span 12;
      margin-bottom: space(xl);
    }
  }

  &--is-intro &__links {
    @include max-width-container;
    @include get-grid-outer-gaps;

    margin-top: calc(-1 * var(--spacings-xxl)); // substract inner padding

    @include mq(m) {
      margin-top: calc(-1 * var(--spacings-m)); // substract inner padding
    }
  }

  &__link {
    color: color(neutral, 0);
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    justify-content: space-between;
    padding: space(xs) space(l) space(xs) space(s);

    @include mq(m) {
      padding: space(s);
    }

    @include mq(l) {
      grid-column: 1 / span 12;
      padding: space(m) space(xl) space(m) space(l);
    }

    &:first-of-type {
      background-color: color(primary);
    }

    &:nth-of-type(2) {
      background-color: color(primary, 30);
    }

    span {
      display: block;
    }

    span + span {
      margin-top: space(xxs);
    }
  }

  &__link-icon {
    bottom: 50%;
    position: absolute;
    right: 10px;
    transform: translateY(50%);
    transition: right 200ms ease-in-out;

    @include mq(m) {
      bottom: 33px;
      right: 40px;
      transform: translateY(0);
    }
  }

  &__link:hover &__link-icon {
    @include mq(m) {
      right: 30px;
    }
  }

  // *************** Variations ***************
  &--dark &__main {
    color: color(neutral, 0);
  }

  &--light &__main {
    color: color(neutral);
  }

  // *************** Animation ***************
  // --- MAIN / LOOP ---
  // inital animation style & animation transition
  &[data-animatable-module] &__main,
  &[data-animatable-module] &__loop {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity bezier('highlight') time('xs'),
      transform bezier('highlight') time('xs');
  }
  // in viewport style
  &[data-animatable-module].is-in-viewport &__main,
  &[data-animatable-module].is-in-viewport &__loop {
    opacity: 1;
    transform: translateY(0);
  }

  // --- LINK ---
  // inital animation style
  &[data-animatable-module] &__link {
    opacity: 0;
    transform: translateY(20px);
  }
  // animation transition (first link)
  &[data-animatable-module] &__link:first-child {
    transition: opacity bezier('entrance') time('s') time(('xs', 'm')),
      transform bezier('entrance') time('s') time(('xs', 'm'));
  }
  // animation transition (second link)
  &[data-animatable-module] &__link:nth-child(2) {
    transition: opacity bezier('entrance') time('s') time(('xs', 'm', 'xs')),
      transform bezier('entrance') time('s') time(('xs', 'm', 'xs'));
  }
  // in viewport style
  &[data-animatable-module].is-in-viewport &__link {
    opacity: 1;
    transform: translateY(0);
  }
}

// *************** Print Styles ***************
@media print {
  .switch {
    &__background {
      display: none;
    }
  }
}
