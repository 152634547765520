.event-detail {
  padding: space(xl) 0;
  background-color: #f6f6f6;

  &__wrapper {
    @include max-width-container;
    @include get-grid;
  }

  &__headline {
    padding-bottom: space(l);
  }

  &__infos {
    display: flex;
    flex-wrap: wrap;
    gap: space(m);
  }

  &__info {
    display: flex;
    flex: 1 1 0px;
    gap: space(xs);
    min-width: 222px;
  }

  .form {
    margin-top: space(l);
  }

  &__headline,
  &__infos,
  .form {
    grid-column: 1 / span 6;
    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  &__infos .text * {
    @include typography(copy);
  }
}
