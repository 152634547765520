.application-multistep {
  .multistep-form {
    @include max-width-container;
    @include get-grid-outer-gaps;
    margin-top: space(m);
  }
}

.application-multistep-complete {
  @include max-width-container;
  &__main-sub {
    @include get-grid;
    background-color: color(primary);
    color: color(neutral, 0);
  }

  &__vacancy {
    grid-column: 1 / span 12;
    padding-top: space(m);
    padding-bottom: space(m);
  }

  &__wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: inherit;
    align-items: center;
  }
  .richtext__wrapper {
    text-align: center;
  }

  &__icon {
    width: 48px;
    height: 48px;
    color: color(success, 50);
    margin-top: space(m);

    @include mq(m) {
      width: 72px;
      height: 72px;
    }
    @include mq(l) {
      width: 90px;
      height: 90px;
    }
  }
  .contact--has-background {
    background-color: color(neutral, 10);
  }
  .teaser-container__header {
    text-align: left;
    grid-column: 1 / span 4;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 1 / span 8;
    }

    @include mq(l) {
      grid-column: 1 / span 12;
    }
  }
}
