.banner {
  background-color: color(primary);
  color: color(neutral, 10);
  overflow: hidden;
  position: relative;
  z-index: get-z-index('banner');

  &__wrapper {
    @include max-width-container;
    @include get-grid;

    @include mq(s) {
      grid-template-rows: auto 1fr auto;
    }
  }

  &__content {
    grid-column: 1 / span 4;
    margin: space(l) 0 space(l);
    z-index: get-z-index('content', 'banner');

    @include mq(s) {
      grid-column: 1 / span 3;
      grid-row: 2 / span 1;
      display: flex;
      flex-direction: column;
      margin: space(xl) 0 space(l);
    }

    @include mq(m) {
      grid-column: 1 / span 4;
      padding-left: space(l);
    }

    @include mq(l) {
    }
  }

  &__overline.text {
    margin-bottom: space(s);
  }

  &__headline.text {
    margin-bottom: space(m);

    @include mq(s) {
      margin-bottom: space(l);
    }
  }

  &__copy {
    margin-bottom: space(s);
  }

  &__link.link {
    margin-top: 0;

    @include mq(s) {
      margin-top: space(s);
    }
  }

  &__back-btn {
    color: color(neutral, 0);
  }

  &__image-container {
    grid-column: 1 / span 4;
    margin: 0 calc(-1 * #{get-grid-outer-gap('default')});
    position: relative;

    @include mq(s) {
      grid-row: 1 / span 3;
      grid-column: 3 / span 4;
      margin: 0;
      margin-right: calc(-1 * #{get-grid-outer-gap('s')});
    }

    @include mq(m) {
      margin: 0;
      grid-column: 4 / span 9;
    }
  }

  &--default &__image-container {
    @include mq(s) {
      grid-row: 1 / span 3;
      margin: 0;
      margin-right: calc(-1 * #{get-grid-outer-gap('s')});
      overflow: hidden;
      display: flex;
    }
  }

  &--fit-image &__image-container {
    margin-left: calc(-1 * #{get-grid-outer-gap('default')});
    overflow: hidden;
    min-height: auto;

    @include mq(s) {
      grid-column: 1 / span 6;
      margin-left: calc(-1 * #{get-grid-outer-gap('s')});
    }

    @include mq(m) {
      grid-column: 1 / span 12;
      margin-right: calc(-1 * #{get-grid-outer-gap('s')});
    }
  }

  &--fit-image &__image-container:after {
    background: linear-gradient(to top, transparent 0%, #314e52 90%);
    content: '';
    height: 10%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: get-z-index('gradient', 'banner');

    @include mq(s) {
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 85%
      );
      height: auto;
      bottom: 0;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &--default &__image-container::before {
    background: linear-gradient(to top, transparent 0%, #314e52 90%);
    content: '';
    height: 10%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: get-z-index('gradient', 'banner');

    @include mq(s) {
      background: linear-gradient(to left, transparent 0%, #314e52 100%);
      bottom: 0;
      height: auto;
      right: auto;
      width: 50%;
    }

    @include mq(m) {
      width: 40%;
    }
  }

  &--default &__image-container::after {
    content: '';
    pointer-events: none;

    @include mq(s) {
      background: linear-gradient(to right, transparent 0%, #314e52 100%);
      bottom: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 20%;
      z-index: get-z-index('gradient', 'banner');
    }
  }

  &--default &__image.image {
    margin: auto;
    display: block;
  }

  &--fit-image &__image.image {
    @include mq(s) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: transparent;
      z-index: get-z-index('image', 'banner');
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
