@function get-typography-theme($theme) {
  @if map-has-key($typographies, $theme) {
    @return map-get($typographies, $theme);
  } @else {
    @error "ERROR: #{$theme} does not exist in #{$typographies}";
  }
}

@function get-typography($key) {
  // TODO how to check if all typographies are definied in every theme
  $baseTheme: get-typography-theme('default');

  @if map-has-key($baseTheme, $key) {
    @return map-get($baseTheme, $key);
  } @else {
    @error "ERROR: #{$key} does not exist in #{$baseTheme}";
  }
}

// TODO dublicate
// TODO mapping not needed anymore
// @mixin get-font-family($typographieMap) {
//   @if map-has-key($typographieMap, 'family') {
//     $fontFamilyKey: map-get($typographieMap, 'family');

//     @if map-has-key($fontFamilys, $fontFamilyKey) {
//       $fontFamily: map-get($fontFamilys, $fontFamilyKey);
//       font-family: #{$fontFamily};
//     } @else {
//       @error "ERROR: #{$fontFamilyKey} does not exist in #{$fontFamilys}";
//     }
//   } @else {
//     font-family: inherit;
//   }
// }

// @mixin get-font-weight($typographieMap) {
//   @if map-has-key($typographieMap, 'weight') {
//     font-weight: map-get($typographieMap, 'weight');
//   } @else {
//     font-weight: 'normal';
//   }
// }

@mixin typography($name) {
  $map: get-typography($name);

  // @include get-font-weight($map);
  // @include get-font-family($map);

  font-display: swap;
  font-family: var(--#{$name}-font-family);
  font-weight: var(--#{$name}-font-weight);
  font-size: var(--#{$name}-font-size);
  letter-spacing: var(--#{$name}-letter-spacing);
  line-height: var(--#{$name}-line-height);
}
