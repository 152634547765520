.richtext {
  /***** has-grid styles ***************************/
  &--has-grid {
    @include get-grid;
  }

  &--has-grid &__wrapper {
    grid-column: 1 / span 4;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 4 / span 6;
    }
  }

  &--has-grid.richtext--has-background &__wrapper {
    background-color: var(--color-neutral-20);
    padding: space(m) space(s);

    @include mq(l) {
      grid-column: 3 / span 8;
      padding: space(l) 13%;
    }
  }

  /***** text styles ***************************/
  li,
  p {
    @include typography(copy);
  }

  &--small li,
  &--small p {
    @include typography(copy-small);
  }

  small {
    @include typography(copy-small);
  }

  h3 {
    @include typography(headline-2);
  }

  &--small h3 {
    @include typography(headline-3);
  }

  h4 {
    @include typography(headline-3);
  }

  &--small h4 {
    @include typography(copy);
  }

  strong {
    font-weight: 700;
  }

  b {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  h3,
  h4 {
    strong {
      font-weight: 400;
    }
  }

  /***** link styles ***************************/
  a {
    // border-bottom: 1px solid color(neutral);

    color: color(neutral);
    text-decoration: underline;

    @include mouseHoverState();
  }

  /***** list styles ***************************/
  ul,
  ol {
    list-style-position: outside;
    margin-left: space(xs);
    padding-left: space(s);

    li {
      margin-bottom: space(xxs);
      position: relative;
    }

    li:before {
      color: color(primary);
      left: -22px;
      position: absolute;
    }
  }

  // ordered list styles
  ol {
    counter-reset: item;
    list-style-type: none;
  }

  ol li {
    counter-increment: item;
  }

  ol li:before {
    content: counter(item, decimal) ''; /*(1, 2, 3, ... z) */
    font-weight: 700;
    width: 12px;
  }

  ul ul,
  ol ol {
    margin-top: space(xxs);
  }

  ol ol li:before {
    content: counter(item, lower-alpha) ''; /*(a, b, c, ... z) */
  }

  // unorderd list styles based on themes
  .theme-color-standard & ul li:before {
    content: url("data:image/svg+xml, %3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .229 7.771 4 4 7.771.229 4z' fill='%23314e52' fill-rule='nonzero'/%3E%3C/svg%3E");
    width: 11px;
  }

  .theme-color-executive & ul li:before {
    content: url("data:image/svg+xml, %3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .229 7.771 4 4 7.771.229 4z' fill='%23484d4f' fill-rule='nonzero'/%3E%3C/svg%3E");
    width: 11px;
  }

  .theme-color-standard & ol ol ol li:before {
    content: url("data:image/svg+xml, %3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .229 7.771 4 4 7.771.229 4z' fill='%23314e52' fill-rule='nonzero'/%3E%3C/svg%3E");
    width: 11px;
  }

  .theme-color-executive & ol ol ol li:before {
    content: url("data:image/svg+xml, %3Csvg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .229 7.771 4 4 7.771.229 4z' fill='%23484d4f' fill-rule='nonzero'/%3E%3C/svg%3E");
    width: 11px;
  }

  /***** spacings ***************************/
  p + p {
    margin-top: space(s);
  }

  p + h3,
  p + h4 {
    margin-top: space(l);
  }

  p + ul,
  p + ol {
    margin-top: space(s);
  }

  h3:not(:last-child),
  h4:not(:last-child),
  ul:not(:last-child),
  ol:not(:last-child) {
    margin-bottom: space(m);
  }

  &--small h3:not(:last-child),
  &--small h4:not(:last-child),
  &--small ul:not(:last-child),
  &--small ol:not(:last-child) {
    margin-bottom: space(xs);
  }
}
