.range-slider {
  // &__wrapper {
  // }

  &__label.text {
    color: color(neutral);
    display: block;
    margin-bottom: space(xs);
  }

  .MuiSlider-root {
    margin-top: 40px;
    height: space(xxs);
    padding: space(xxs) 0;
  }

  .MuiSlider-rail {
    background-color: color(primary, 20);
  }

  .MuiSlider-track {
    background-color: color(primary);
  }

  .MuiSlider-thumb {
    background-color: color(primary);
    width: 18px;
    height: 18px;
  }

  .MuiSlider-thumb:hover,
  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 0px 0px 6px rgb(49 78 82/20%);
  }

  .MuiSlider-valueLabel {
    color: color(neutral, 0);
    background-color: color(primary);
    border-radius: 13px;
    padding: 2px space(xs);
  }

  .MuiSlider-valueLabelLabel {
    @include typography(copy-small);
    font-weight: bold;
  }
}
