.accordion {
  margin: 0;
  width: 100%;

  /***** has-grid styles ***************************/
  &--has-grid {
    @include get-grid;
  }

  &--has-grid &__list {
    grid-column: 1 / span 4;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 4 / span 6;
    }
  }

  &--has-grid.richtext--has-background &__list {
    background-color: var(--color-neutral-20);
    padding: space(m) space(s);

    @include mq(l) {
      grid-column: 3 / span 8;
      padding: space(l) 13%;
    }
  }

  &__headline.text {
    grid-column: 1 / span 4;
    margin-bottom: space(xs);
    text-align: center;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
      margin-bottom: space(s);
    }
  }

  /***** accordion items ***************************/
  &__term {
    &:not(:first-child) {
      border-top: 1px solid color(primary, 20);
    }
  }

  &__button {
    align-items: center;
    appearance: none;
    background: none;
    border: none;
    display: flex;
    justify-content: space-between;
    outline: none;
    padding: space(s) 0;
    text-align: left;
    width: 100%;

    @include keyboardFocus(false);

    &[aria-expanded='true'] {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__button-text {
    padding-right: space(xs);
  }

  &__button-icon {
    flex-shrink: 0;
  }

  &__panel {
    margin: 0;
    padding: 0 0 space(s);
  }
}

// *************** Print Styles ***************
@media print {
  .accordion {
    &__panel[hidden] {
      display: block;
    }
  }
}
