@mixin hide-visually {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  //   clip-path: inset(50%);
  // height: 1px;
  // margin: -1px;

  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin keyboardFocus($hasMobileOffset: true, $hasDesktopOffset: false) {
  &:focus {
    outline: 0;
  }

  &:focus-visible {
    outline-color: color(interaction);
    outline-style: solid;
    outline-width: 2px;

    @if $hasMobileOffset {
      outline-offset: space(xxs);
    }

    @if $hasDesktopOffset {
      @include mq(m) {
        outline-offset: space(xxs);
      }
    }
  }
}

@mixin mouseHoverState($value: 0.7) {
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: $value;
  }
}
