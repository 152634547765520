.steps {
  @include max-width-container;

  overflow: hidden;
  position: relative;
  background-color: color(primary, 20);
  padding-top: space(l);
  padding-bottom: space(l);
  color: color(neutral);

  @include mq(m) {
    padding-top: space(xl);
    padding-bottom: space(xl);
  }

  &__bg {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 300%;
    opacity: 0.1;

    @include mq(m) {
      right: auto;
      right: 30px;
      top: -30px;
      height: auto;
      width: 250%;
      max-width: 3500px;
    }
  }

  &__grid {
    position: relative;
    @include get-grid;
  }

  &__header {
    grid-column: 1 / span 4;
    text-align: center;
    margin-bottom: space(l);

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      margin-bottom: space(xl);
      grid-column: 4 / span 6;
    }
  }

  &__list {
    position: relative;
    z-index: get-z-index('list', 'steps');

    grid-column: 1 / span 4;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 2 / span 5;
    }

    &:before {
      position: absolute;
      content: '';
      background: color(neutral);
      width: 1px;
      top: 0;
      left: 50%;
      height: 100%;
      z-index: get-z-index('line', 'steps');

      @include mq(m) {
        top: 50px;
        bottom: 50px;
        height: auto;
        left: -50px;
      }
    }
  }

  &__lead-in.text {
    margin-top: space(s);
  }

  &__button {
    @extend .button;
    background: color(neutral, 0);
    border-radius: 0;
    border: 0 none;
    color: color(neutral);
    width: 100%;
    padding: space(xs) space(s);
    justify-content: flex-start;

    @include mq(m) {
      height: 100px;
      padding: space(s) space(m);
    }

    &:focus-visible {
      border-color: color(interaction);
    }

    &:not(:last-of-type) {
      margin-bottom: space(xs);
    }

    &:before {
      @include mq(m) {
        display: block;
        position: absolute;
        content: '';
        background: color(neutral);
        height: 1px;
        z-index: get-z-index('line', 'steps');
        top: 50px;
        left: -50px;
        width: 50px;
      }
    }
  }

  &__button--active {
    background: color(accent);
  }

  &__button-count.text {
    line-height: 1;
    margin-top: -1px;
    margin-right: space(s);

    @include mq(m) {
      margin-top: -3px;
      margin-right: space(s);
    }
  }

  &__button-label.text {
    text-align: left;
    margin-right: space(s);

    @include mq(m) {
      margin-right: space(m);
    }
  }

  &__button-icon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;

    @include mq(m) {
      width: 36px;
      height: 36px;
    }
  }

  &__button-forward {
    width: 24px;
    height: 24px;
    margin-left: auto;
    flex-shrink: 0;
    transform: rotate(90deg);

    @include mq(m) {
      transform: none;
      width: 40px;
      height: 40px;
    }
  }

  &__container {
    @include max-width-container;
    @include get-grid;
    display: none;

    @include mq(m) {
      display: grid;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      z-index: get-z-index('container', 'steps');
    }
  }

  &__container--sticky {
    @include mq(m) {
      position: fixed !important;
    }
  }

  &__container--to-bottom {
    @include mq(m) {
      top: auto;
      bottom: 0;
    }
  }

  &__wrapper {
    @include mq(m) {
      position: relative;
      grid-column: 7 / span 6;
      height: 100%;
    }
  }

  &__content {
    transition: opacity time('s') bezier('entrance') time(('s'));

    @include mq(m) {
      background-color: transparent;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      max-height: 100vh;
      overflow: auto;
    }
  }

  &__container--to-bottom &__content {
    @include mq(m) {
      top: auto;
      bottom: 0;
    }
  }

  &__content--mobile {
    display: none;
    position: relative;
    color: color(primary);
    background-color: color(accent);

    &:not(:last-of-type) {
      margin-bottom: space(xs);
    }

    &:last-of-type {
      margin-top: space(xs);
    }

    @include mq(m) {
      display: none;
    }
  }

  &__content--active {
    display: block;

    @include mq(m) {
      opacity: 1;
    }
  }

  &__content-wrapper {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: space(s) space(m) space(m);
    color: color(neutral);

    @include mq(m) {
      padding: space(m) space(l) space(m);
    }
  }

  // &__content--active &__content-wrapper {
  // }

  &__indicator {
    width: space(l);
    height: space(l);
    display: flex;
    justify-content: center;
    align-items: center;
    // border-radius: 50%;
    // border-width: 2px;
    // border-style: solid;
    // border-color: inherit;

    span.text {
      color: inherit;
    }
  }

  &__content-icon {
    height: 80px;
    width: 80px;

    @include mq(s) {
      height: 100px;
      width: 100px;
    }
  }

  &__content-headline.text {
    display: none;

    @include mq(m) {
      display: block;
      margin-bottom: space(xxs);
    }
  }

  &__content-copy.text {
    margin-top: space(xs);

    @include mq(s) {
      margin-top: space(s);
    }
  }

  &__content-information.text {
    margin-top: space(xs);
    text-transform: uppercase;

    @include mq(m) {
      margin-top: space(xxs);
    }
  }

  &__content-headline.text,
  &__content-copy.text {
    text-align: center;
  }

  // *************** Variations ***************
  // &--default {
  //   background-color: transparent;

  //   .text {
  //     color: color(neutral);
  //   }
  // }
}

// *************** Print Styles ***************
@media print {
  .steps {
    &__content {
      display: block;
    }

    &__content-wrapper {
      display: block;
      padding-top: 0pt;
      padding-bottom: 10pt;
    }

    &__content-icon {
      width: 30pt;
      height: 30pt;
    }

    &__content-information.text,
    &__content-copy.text {
      text-align: left;
      margin-top: 10pt;
    }
  }
}
