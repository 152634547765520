.button {
  align-items: center;
  appearance: none;
  border-radius: space(xxs);
  border-style: solid;
  border-width: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: 0;
  padding: space(xs) space(m);
  position: relative;
  transition: color 200ms, background-color 200ms, border-color 200ms;

  &[disabled] {
    color: color(neutral, 50);
    pointer-events: none;
    user-select: none;
  }

  &__loading {
    display: none;
  }

  &--is-loading .text.text--button {
    opacity: 0;
  }

  &--is-loading &__loading {
    display: block;
    height: 6px;
    position: absolute;
  }

  &__icon-wrapper {
    display: block;

    &:not(:only-child) {
      margin-right: space(xs);
    }
  }

  &__icon {
    color: currentColor;
    fill: currentColor;
    transition: color 200ms, background-color 200ms;
  }

  &--small {
    border-width: 2px;
    padding: space(xxs) space(m);
  }

  &--primary {
    background-color: color(accent);
    border-color: color(accent);
    color: color(neutral);

    &:hover {
      background-color: color(accent, 70);
      border-color: color(accent, 70);
      color: color(neutral, 0);
    }

    &:active {
      background-color: color(accent, 80);
      border-color: color(accent, 80);
      color: color(neutral, 0);
    }

    &:focus {
      background-color: color(accent);
      border-color: color(accent);
      color: color(neutral);
    }

    &:focus-visible {
      border-color: color(interaction);
      outline-color: color(neutral, 0);
      outline-offset: -4px;
      outline-style: solid;
      outline-width: 2px;
    }

    &[disabled] {
      color: color(neutral, 0);
      background-color: color(neutral, 50);
      border-color: color(neutral, 50);
    }
  }

  &--secondary {
    background-color: color('neutral', 0);
    border-color: color(accent);
    color: color(neutral);

    &:hover {
      border-color: color(accent, 70);
      color: color(neutral, 70);
    }

    &:active {
      border-color: color(accent, 70);
      color: color(neutral);
    }

    &:focus {
      color: color(neutral);
    }

    &:focus-visible {
      border-color: color(interaction);
    }

    &[disabled] {
      border-color: color(neutral, 50);
    }
  }

  &--tertiary {
    background-color: transparent;
    border: 0 none;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    color: color(neutral);
    padding: space(xs) 0;

    &:focus-visible {
      border-bottom-color: color(interaction);
    }
  }

  &--tertiary &__icon-wrapper {
    align-items: center;
    background-color: color(accent);
    border-radius: 50%;
    display: flex;
    height: space(l);
    justify-content: center;
    margin-left: space(xs);
    margin-right: 0;
    order: 1;
    width: space(l);
  }

  &--tertiary &__icon {
    color: color(neutral);
  }

  &--tertiary:hover &__icon {
    color: color(neutral, 0);
  }

  &--hidden-label {
    padding: space(xs);
    border-radius: 50%;
  }

  &--hidden-label &__icon-wrapper {
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
    background-color: transparent;
  }

  &--hidden-label.button--tertiary:hover &__icon {
    color: color(neutral, 70);
  }

  &--link & {
    &__icon-wrapper {
      background-color: transparent;
      margin: 0;
      transition: transform 200ms ease-in-out;
    }

    &__icon {
      width: 30px;
      height: 30px;
    }
  }
  &--link:hover {
    & .button__icon {
      color: color(neutral, 100);
    }

    & .button__icon-wrapper {
      transform: translateX(space(xxs));
    }
  }
}

a.button {
  outline: 0;
}
