.quote {
  @include get-grid;
  @include max-width-container;

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    grid-column: 1 / span 4;
    padding: 0 space(m);

    @include mq(s) {
      justify-content: center;
      grid-column: 1 / span 2;
      justify-content: center;
    }

    @include mq(m) {
      grid-column: 3 / span 2;
      justify-content: center;
      padding: 0;
    }
  }

  &__image {
    width: 110px;
    margin-bottom: space(s);

    @include mq(s) {
      margin-bottom: 0;
    }

    @include mq(m) {
      width: 200px;
    }

    img {
      width: 100%;
      max-width: 200px;
      border-radius: 50%;
      height: auto;
    }
  }
  &__content {
    grid-column: 1 / span 4;
    padding: 0 space(m);

    @include mq(s) {
      grid-column: 3 / span 4;
      justify-content: center;
      padding: 0;
    }
    @include mq(m) {
      margin-left: space(l);
      grid-column: 5 / span 6;
      padding: 0;
    }
  }

  &__caption {
    margin-top: space(l);
  }
  &__description.text {
    margin-top: space(xxs);
  }
}
