.numbers {
  &__wrapper {
    @include max-width-container;
    @include get-grid;
  }

  &__header {
    grid-column: 1 / span 4;
    text-align: center;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  &__content {
    grid-column: 1 / span 4;
    margin-top: space(l);

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 1 / span 12;
      // margin-top: space(xl);
    }
  }

  &__ul,
  &__ol {
    @include mq(s) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__item {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: space(l);

    @include mq(s) {
      flex-shrink: 0;
      margin-bottom: 0;
      padding: 0 space(s);
      width: 50%;
    }

    @include mq(m) {
      padding: 0 space(m);
      width: 33%;
    }

    &:nth-child(3) {
      @include mq(s) {
        margin-top: space(l);
      }

      @include mq(m) {
        margin-top: 0;
      }
    }
  }

  &__item-icon {
    height: 80px;
    width: 80px;
    margin-bottom: space(xxs);

    @include mq(s) {
      height: 100px;
      width: 100px;
    }
  }

  &__item-prefix.text {
    margin-bottom: space(xxs);
  }

  &__item-unit.text {
    margin-top: space(xs);
  }

  &__item-copy.text {
    margin-top: space(s);
  }

  &__item-prefix,
  &__item-number,
  &__item-unit,
  &__item-copy.text {
    text-align: center;
  }

  // *************** Variations ***************
  &--default {
    background-color: transparent;

    .text {
      color: color(neutral);
    }
  }

  &--light {
    background-color: color(primary, 10);
    padding-bottom: space(xl);
    padding-top: space(xl);

    @include mq(m) {
      padding-bottom: space(xxl);
      padding-top: space(xxl);
    }

    .text {
      color: color(primary);
    }
  }
}
