.table {
  &--has-grid {
    @include get-grid;
  }

  &__headline.text {
    margin-bottom: space(s);
  }

  &--has-grid &__headline {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 6;
    }
  }

  &__container {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
  }

  &--has-grid &__container,
  &--has-grid &__notes {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }

  table {
    min-width: 100%;
    text-align: left;
  }

  &--is-html table {
    text-align: left;
  }

  th,
  td {
    text-align: left;
    padding: space(xs) space(s);
    word-break: keep-all;
    border-bottom: 1px solid color(neutral, 20);

    span {
      line-height: 1;
    }

    strong,
    b {
      display: block;
      font-weight: 700;
      margin-bottom: space(xxs);
    }

    em,
    b,
    .bold {
      color: color(accent, 90);
      font-weight: 700;
    }
  }

  th {
    cursor: pointer;
    background: color(neutral, 20);
    border-bottom: 1px solid color(neutral, 50);
    padding: space(xs) space(s);

    .table__heading-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: left;
      width: max-content;
      position: relative;
    }
    .text {
      display: inline-block;
      margin-right: space(xxs);
    }
  }

  &__head-info {
    display: inline-flex;
    align-items: center;
    justify-content: left;
    width: max-content;
    font-size: 14px;
  }

  &__notes {
    margin-top: space(m);
  }

  &__sorting-indicator {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    margin-top: -4px;
  }

  &__sorting-indicator > svg {
    display: none;
    height: 20px;
  }

  th[data-sort-direction='asc'] .table__sorting--asc {
    display: block;
  }
  th[data-sort-direction='desc'] .table__sorting--desc {
    display: block;
  }
  th[data-sort-direction='none'] .table__sorting--none {
    display: block;
  }

  & .red {
    color: color(warning);
  }
  & .green {
    color: color(success);
  }
  & .downIcon::after {
    content: ' ↓';
  }
  & .upIcon::after {
    content: ' ↑';
  }
}
