.testimonials {
  background-color: color(primary, 10);
  position: relative;

  &__wrapper {
    @include max-width-container;
    @include get-grid-outer-gaps;

    @include mq($until: m) {
      padding-left: 0;
      padding-right: 0;
    }

    padding-top: space(s);
    padding-bottom: space(s);

    @include mq(m) {
      padding-top: space(xl);
      padding-bottom: space(xl);
    }
  }

  &__slide {
    position: relative;
    height: 100%;
    padding-top: 50px;

    @include mq($until: m) {
      margin-left: get-grid-outer-gap('default');
      margin-right: get-grid-outer-gap('default');
    }

    @include mq(m) {
      padding-top: 60px;
    }
  }

  &[data-slider-length='1'] &__slide {
    max-width: 800px;
    margin: auto;
  }

  &__figure {
    display: flex;
    height: 100%;
    background-color: color(neutral, 20);
    border: 1px solid color(neutral, 10);
    border-radius: 6px;
    flex-direction: column;
    text-align: center;
    padding: space(l);
    padding-top: 70px;
    transition: background-color 0.5s ease, border-color 0.5s ease;

    @include mq(m) {
      padding-top: 90px;
    }
  }

  &[data-slider-length='2'] &__figure,
  .keen-slider__slide--active &__figure {
    background-color: color(neutral, 10);
    border-color: color(neutral, 50);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(0%);
    height: 100px;
    margin-bottom: space(s);
    width: 100px;

    @include mq(s) {
      margin-bottom: 0;
      margin-right: space(l);
    }

    @include mq(m) {
      height: 120px;
      margin-right: space(xl);
      width: 120px;
    }

    img {
      border-radius: 50%;
    }
  }

  &__caption {
    margin-top: auto;
  }

  &__quote {
    padding-bottom: space(s);
  }

  &__description {
    padding-top: space(xxs);
  }

  &__buttons {
    @include mq(m) {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: space(l);

      button {
        margin: 0 calc(space(xs) / 2);
      }
    }
  }

  &__button-prev {
    position: absolute;
    top: 50%;
    left: space(xxs);

    @include mq(m) {
      position: static;
      top: 0;
    }
  }

  &__button-next {
    position: absolute;
    top: 50%;
    right: space(xxs);

    @include mq(m) {
      position: static;
      top: 0;
    }
  }

  &[data-slider-length='2'] &__buttons {
    width: 100%;

    @include mq(m) {
      display: none;
    }
  }
}
