.favorites-list {
  @include max-width-container;

  &__section {
    margin-bottom: space(l);

    @include mq(m) {
      margin-bottom: space(xl);
    }
  }

  &__no-results {
    @include get-grid;

    p {
      grid-column: 1 / span 4;
      padding: space(s) 0;

      @include mq(s) {
        grid-column: 1 / span 4;
      }

      @include mq(m) {
        grid-column: 2 / span 8;
      }
    }
  }

  &__teasers {
    margin-bottom: space(m);

    @include mq(m) {
      margin-bottom: space(l);
    }
  }

  &__ctas {
    @include get-grid-outer-gaps;
    display: flex;
    flex-direction: column;

    @include mq(s) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__cta-submit {
    margin-top: space(xs);

    @include mq(s) {
      margin-top: 0;
    }
  }

  &__maximum-selected.text {
    display: block;
    margin-top: space(xs);
  }

  &__loader {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: block;
    margin-top: space(m);
    margin-bottom: space(xxl);
  }
}
