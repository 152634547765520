.input {
  // [type='hidden'] {
  // }

  // Input Label
  label {
    color: color(neutral);
    display: block;
    margin-bottom: space(xxs);
  }

  &--hidden-label label {
    @include hide-visually();
  }

  // Native Input
  input {
    background: color(neutral, 0);
    border: 2px solid color(neutral, 70);
    border-radius: 0;
    color: color(neutral);
    outline: none;
    padding: space(xxs) space(xs);
    transition: color 200ms, background-color 200ms, border-color 200ms;
    width: 100%;
  }

  input::placeholder {
    color: color(neutral, 70);
    opacity: 1;
    text-overflow: ellipsis;
  }

  input,
  input::placeholder {
    @include typography(copy);
  }

  &.form-error input {
    border-color: color(warning);
  }

  input:hover {
    border-color: color(neutral, 80);
  }

  input:disabled {
    border-color: color(neutral, 50);

    &::placeholder {
      color: color(neutral, 50);
    }
  }

  input:focus-visible {
    border-color: color(interaction);

    & ~ .input__error {
      display: none;
    }
  }

  // &--success input {
  //   border-color: color(success);
  // }

  // Error Message
  &__error.text {
    color: color(warning);
    display: none;
    margin-top: space(xxs);
  }

  &.form-error &__error {
    display: block;
  }

  // Hint Message
  &__hint.text {
    color: color(neutral, 80);
    display: block;
    margin-top: space('xxs');
  }

  &__hint.text {
    color: color(neutral, 70);
  }
}
