.vacancy-ctas {
  @include get-grid;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    grid-column: 1 / span 4;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 4 / span 6;
    }
  }

  &__wrapper > * {
    margin-bottom: space(s);
    flex-basis: 100%;
    flex-shrink: unset;

    @include mq(m) {
      flex-basis: unset;
      margin-right: space(s);
    }
  }

  svg {
    fill: black;
  }
}

// *************** Print Styles ***************
@media print {
  .vacancy-ctas {
    display: none;
  }
}
