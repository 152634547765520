.multistep-form {
  &__step {
    display: none;
  }

  &__step--active {
    display: block;

    .multistep-form__controls {
      display: flex;
    }
  }

  &__step__item {
    margin-bottom: space(s);

    @include mq(s) {
      margin-bottom: space(l);
    }
  }

  &__progress-bar {
    margin-top: space(xs);
    margin-bottom: space(xs);

    @include mq(s) {
      margin-bottom: space(l);
    }

    p {
      margin-bottom: -2px;
    }

    &__steps {
      display: flex;
      gap: space(xs);
      width: 100%;

      &__step {
        position: relative;
        width: 100%;
        height: 4px;
        background-color: color('neutral', 20);
        border-radius: 3px;
      }

      &__step::after {
        content: '';
        width: 0%;
        height: 100%;
        background-color: color('primary');
        display: block;
        border-radius: 3px;
        transition: all 0.5s ease;

        @media (prefers-reduced-motion) {
          transition: all 0s ease;
        }
      }

      &__step--active::after {
        width: 100%;
      }
    }
  }

  &__mandatory.text {
    display: block;
    margin-bottom: space(s);
    margin-top: space(m);
  }

  &__controls {
    overflow: hidden;
    display: none;
    flex-direction: column;
    gap: space(m);
    margin-top: space(m);
    width: 100%;
    margin-bottom: space(m);

    @include mq(s) {
      width: max-content;
    }

    .button--tertiary {
      padding: unset;
    }
  }

  &__row {
    @include get-grid(
      (
        default: 4,
        m: 8
      ),
      false
    );
  }

  &__field {
    margin-bottom: space(m);
  }

  &__field__info-point {
    display: flex;
    gap: space(xs);

    svg {
      width: 94px;
      height: 24px;
    }
  }

  &__column {
    @include mq($until: m) {
      grid-column: 1 / span 4;
    }

    @include mq(m) {
      @for $i from 1 through 12 {
        &[data-form-columns='#{$i}'] {
          grid-column-end: span #{$i};
        }
      }
    }
  }

  .input-group {
    margin-bottom: space(m);
  }
}
