.link-list {
  background-color: color(primary, 10);

  &__wrapper {
    @include max-width-container;
    @include get-grid;

    padding-bottom: space(xl);
    padding-top: space(xl);

    @include mq(m) {
      padding-bottom: space(xxl);
      padding-top: space(xxl);
    }
  }

  &__header {
    grid-column: 1 / span 4;
    text-align: center;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  &__lead-in.text {
    margin-top: space(xs);
  }

  &__lead-in.text,
  &__headline.text {
    color: color(primary);
  }

  &__ul {
    row-gap: space(xxs);
    @include get-grid((), false);

    @include mq(s) {
      grid-column: 1 / span 6;
    }
  }

  &__content {
    grid-column: 1 / span 4;
    margin-top: space(m);

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 1 / span 12;
      margin-top: space(l);
    }
  }

  &__item {
    grid-column: span 2;
  }

  &__item-link {
    background-color: color(primary, 10);
    color: color(primary);
    display: block;
    padding: space(m) space(s);
    position: relative;
    transition: color 300ms ease-in-out 300ms,
      background-color 400ms ease-in-out 200ms;
    height: 100%;

    &:hover {
      background-color: color(primary, 70);
      color: color(neutral, 0);
    }

    @include mq(m) {
      padding: space(l) space(s);
    }
  }

  &__item-bg {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 600ms ease-in-out;
    z-index: 0;

    &:after {
      background: linear-gradient(
        180deg,
        transparent 0%,
        rgba(0, 0, 0, 0.5) 80%
      );
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__item-link:hover &__item-bg {
    opacity: 1;
  }

  &__item-bg-image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__item-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
  }

  &__item-icon {
    height: space(xl);
    margin-bottom: space(xs);
    transition: opacity 300ms ease-in-out 300ms;
    width: space(xl);

    @include mq(s) {
      height: 100px;
      margin-bottom: space(m);
      width: 100px;
    }
  }

  &__item-link:hover &__item-icon {
    opacity: 0;
  }

  &__item-label {
    margin-bottom: space(xxs);
    text-align: center;
  }

  // *************** Animation ***************
  &[data-animatable-module] &__header {
    // inital animation style

    opacity: 0;
    position: relative;
    transform: translateY(40px);
    // animation transition

    transition: opacity bezier('highlight') time('xxs'),
      transform bezier('highlight') time('xxs');
    // in viewport style
    &.is-in-viewport {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &[data-animatable-module] &__item {
    // inital style

    opacity: 0;
    position: relative;
    transform: translateY(20px);
    // animation transition

    transition: opacity bezier('entrance') time('m'),
      transform bezier('entrance') time('m');
    // in viewport style
    &.is-in-viewport {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// *************** Print Styles ***************
@media print {
  .link-list {
    &__item-icon {
      width: 30pt;
      height: 30pt;
    }
  }
}
