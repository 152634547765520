.pagination {
  @include max-width-container;

  align-items: center;
  display: flex;
  justify-content: center;
  // @include get-grid;

  margin-bottom: space(s);
  @include get-grid-outer-gaps;

  @include mq(m) {
    margin-bottom: space(xxl);
  }

  ul {
    display: flex;
    flex-grow: 2;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 430px;
  }

  li {
    flex-shrink: 0;
  }

  a {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    min-width: 28px;
    padding: 2px space(xxs);

    &[aria-current='true'] {
      span {
        border-bottom-style: solid;
        border-bottom-width: 2px;
      }
    }

    @include mouseHoverState();
  }

  &__text {
    &:first-child {
      @include mq(m) {
        margin-right: space(xs);
      }
    }

    &:last-child {
      @include mq(m) {
        margin-left: space(xs);
      }
    }
  }
}
