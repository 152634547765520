.drawer {
  position: relative;

  &__section__title {
    background: color(neutral, 20);
    position: sticky;
    top: 0;
    padding: space(xs) space(m);
    z-index: 1;
  }

  &__section__content {
    padding: space(xs) space(m);
  }
}
