.favorites-link {
  position: relative;
  margin-right: space(xxs);
  display: flex;

  @include mq(m) {
    display: inline-block;
  }

  &__icon {
    position: relative;
    z-index: 1;

    svg {
      margin-right: 0;
    }
  }

  &__count.text {
    display: none;
    letter-spacing: 0;
    margin-left: -5px;
    margin-top: -3px;
    background-color: color(accent);
    color: color(neutral);
    border-radius: 50%;
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    z-index: 0;

    @include mq(m) {
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(75%) translateY(-20%);
    }
  }

  &--show-count &__count {
    display: flex;
  }
}
