.range {
  // Input Label
  label {
    color: color(neutral);
    display: block;
    margin-bottom: space(xxs);
  }

  &--hidden-label label {
    @include hide-visually();
  }

  &__wrapper {
    display: flex;
  }

  &__value {
    min-width: space(l);
    padding-left: space(xs);
    text-align: right;
    white-space: pre-wrap;
  }

  // Native Input
  input {
    appearance: none;
    background-color: color(neutral, 70);
    border-radius: 1px;
    height: 3px;
    margin: calc(var(--spacings-m) / 2) 0;
    outline: none;
    transition: background-color 200ms;
    width: 100%;
  }

  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  input::-webkit-slider-thumb {
    appearance: none;
    background-color: color(accent);
    border-radius: 50%;
    cursor: pointer;
    height: space(m);
    transition: background-color 200ms;
    width: space(m);
    z-index: 1;
  }

  input::-moz-range-thumb {
    background-color: color(accent);
    border-radius: 50%;
    cursor: pointer;
    height: space(m);
    transition: background-color 200ms;
    width: space(m);
    z-index: 1;
  }

  &.form-error input {
    background-color: color(warning);
  }

  input:hover {
    background-color: color(neutral, 80);

    &::-moz-range-thumb {
      background-color: color(accent, 90);
    }

    &::-webkit-slider-thumb {
      background-color: color(accent, 90);
    }
  }

  input:disabled {
    background-color: color(neutral, 50);
  }

  input:focus-visible {
    background-color: color(neutral, 80);

    &::-moz-range-thumb {
      background-color: color(interaction);
    }

    &::-webkit-slider-thumb {
      background-color: color(interaction);
    }
  }

  // &--success input {
  //   border-color: color(success);
  // }

  // Error Message
  &__error.text {
    color: color(warning);
    display: none;
    margin-top: space(xxs);
  }

  &.form-error &__error {
    display: block;
  }

  // Hint Message
  &__hint.text {
    color: color(neutral, 80);
    display: block;
    margin-top: space('xxs');
  }
}
