.notes {
  &--has-grid {
    @include get-grid;
  }

  &__note {
    margin-bottom: space(s);
  }

  &--has-grid &__note {
    grid-column: 1 / span 12;

    @include mq(l) {
      grid-column: 2 / span 10;
    }
  }
}
