.footer {
  background-color: color(primary, 70);
  color: color(neutral, 0);
  margin-top: auto; // to align to bottom of body (height: 100vh)
  padding-bottom: calc(var(--spacings-xl) + var(--spacings-l));
  position: relative;

  @include mq(m) {
    padding-bottom: calc(var(--spacings-xxl) + var(--spacings-xl));
  }

  &--small {
    padding-bottom: 0;

    @include mq(m) {
      padding-bottom: 0;
    }
  }

  &__wrapper {
    @include max-width-container;
    @include get-grid-outer-gaps;

    position: relative;
  }

  &__top,
  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq(m) {
      flex-direction: row;
    }
  }

  &__top {
    padding: space(m) 0 space(l);

    @include mq(m) {
      padding: space(l) 0;
    }
  }

  &__bottom {
    border-top: 1px solid color(primary, 30);
    padding: space(m) 0;
  }

  &__intro,
  &__social,
  &__languages,
  &__sub {
    display: flex;
    flex-wrap: wrap;

    @include mq(m) {
      align-items: center;
    }
  }

  &__intro {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @include mq(m) {
      flex-direction: row;
      max-width: 600px;
      width: 60%;
    }
  }

  &__social {
    flex-wrap: wrap;
    margin-top: space(l);

    @include mq(m) {
      margin-top: 0;
    }

    li {
      margin-bottom: space(xxs);
    }

    li:not(:last-of-type) {
      margin-right: space(xs);
    }
  }

  &__social-link {
    @include mouseHoverState();

    svg {
      height: 40px;
      width: 40px;
    }
  }

  &__languages,
  &__sub {
    padding-top: space(s);

    @include mq(m) {
      padding-top: 0;
    }
  }

  // ************* LOGO *************
  &__logo {
    display: inline-block;
    flex-shrink: 0;
    margin-bottom: space(m);

    @include mouseHoverState();

    @include mq(m) {
      margin-bottom: 0;
      margin-right: space(l);
    }
  }

  &__logo-svg {
    width: 67px;

    @include mq(m) {
      width: 72px;
    }

    @include mq(l) {
      width: 90px;
    }
  }

  &__copyright.text {
    padding-right: space(xs);
  }

  &__link {
    color: color(neutral, 0);
    padding: 0 space(xs);

    @include mouseHoverState();
  }

  &__languages &__link {
    color: color(primary, 20);
  }

  &__current.text {
    color: color(neutral, 0);
    padding: 0 space(xs);
  }

  &__item {
    & + & {
      border-left: 1px solid color(primary, 30);
    }
  }

  &__item:first-child &__link,
  &__item:first-child &__current {
    padding-left: 0;
  }

  &__item:last-child &__link,
  &__item:last-child &__current {
    padding-right: 0;
  }
}

// *************** Print Styles ***************
@media print {
  .footer {
    display: none;
  }
}
