.login {
  z-index: get-z-index('login');

  &__wrapper {
    position: relative;
  }

  /* ---------- Background ---------- */
  &__background {
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;

    svg {
      fill: color(neutral);
      height: 100%;
      opacity: 0.03;
      position: relative;
      top: 50%;
      transform: translateX(-75%) translateY(-50%);
      width: auto;

      @include mq(m) {
        top: 50%;
        transform: translateX(-70%) translateY(-50%);
        width: auto;
      }
    }
  }

  &__background-inner {
    @include max-width-container('images');

    height: 100%;
    width: 200%;
    left: -75%;
    overflow: hidden;
    position: relative;

    @include mq(s) {
      width: 120%;
      left: auto;
    }

    @include mq(m) {
      width: 100%;
    }

    &:after {
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 85%
      );
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &--light &__background-inner {
    &:after {
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0) 0%,
        rgba(235, 232, 233, 0.4) 85%
      );
    }
  }

  &__bg-image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  /* ---------- Content ---------- */
  &__content {
    @include max-width-container;
    @include get-grid;
    aspect-ratio: 2.5/1; // min-height equals 16/10
    position: relative;
    z-index: get-z-index('content', 'login');
    margin-top: space(m);
    margin-bottom: space(m);

    @include mq(m) {
      margin-top: space(l);
      margin-bottom: space(l);
    }
  }

  /* ---------- Breadcrumb ---------- */
  // &__breadcrumb {
  //   align-items: center;
  //   color: color(primary, 20);
  //   display: flex;
  //   flex-wrap: wrap;
  //   grid-column: 1 / span 4;
  //   margin-top: space(xs);
  //   position: relative;

  //   @include mq(s) {
  //     grid-column: 1 / span 6;
  //   }

  //   @include mq(m) {
  //     grid-column: 1 / span 12;
  //   }
  // }

  // &__breadcrumb-svg {
  //   margin: 0 space(xxs);
  // }

  // &__breadcrumb-current,
  // &__breadcrumb-link {
  //   align-items: center;
  //   display: flex;
  //   height: space(m);
  //   max-width: 100%;

  //   span {
  //     max-width: 100%;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  //   }
  // }

  // &__breadcrumb-link-inner {
  //   line-height: 1;
  // }

  /* ---------- Main ---------- */
  &__main {
    grid-column: 1 / span 4;
    grid-row: 1 / span 2;
    margin: auto 0;
    padding: space(s);
    background-color: color(neutral, 10);
    color: color(neutral);
    border-radius: 6px;

    @include mq(s) {
      grid-column: 1 / span 4;
    }

    @include mq(m) {
      margin: auto space(m);
      grid-column: 1 / span 6;
      padding: space(l);
    }

    @include mq(l) {
      margin: auto space(l);
    }

    .form__ctas button {
      width: 100%;
    }
  }

  &__header {
    text-align: center;
    padding-bottom: space(xs);

    @include mq(m) {
      padding-bottom: space(s);
    }
  }

  &__overline.text {
    margin-bottom: space(xs);
  }

  &__headline.text {
    display: block;
    grid-column: 1 / span 4;
    position: relative;

    @include mq(m) {
      grid-column: 1 / span 8;
    }
  }

  &__copy.text {
    margin-top: space(xs);
  }

  &__form {
    .form__field,
    .form__link {
      margin-bottom: space(s);
    }

    label span.text--form-label span {
      display: none;
    }

    .form__mandatory {
      display: none;
    }

    .form__ctas {
      flex-direction: column;
    }

    .form__secondary {
      order: 1;
      flex-direction: column;
      margin: 0;
      margin-top: space(xs);
    }

    .form__secondary-seperator.text {
      text-align: center;
      order: -1;
      margin: 0;
      margin-bottom: space(xs);
    }

    .form__secondary-cta {
      display: flex;
      width: 100%;
    }
  }

  &__footnote.text {
    margin-top: space(s);

    a {
      text-decoration: underline;
    }
  }
}
