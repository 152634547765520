.favorites {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: inherit;

  &__main {
    margin-top: space(l);

    @include mq(m) {
      margin-top: space(xxl);
    }
  }
}
