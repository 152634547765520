@mixin searchInput() {
  input[type='text'] {
    background-color: color('neutral', 0);
    border: 0 none;
    border-radius: space(xxs);
    color: color(neutral);
    outline: 0;
    padding: space(xs) space(xs) space(xs)
      calc(var(--spacings-m) + var(--spacings-xs));
    width: 100%;

    @include mq(m) {
      padding: space(xs) space(s) space(xs)
        calc(var(--spacings-l) + var(--spacings-xs));
    }

    @include keyboardFocus(false);
  }

  input[type='text'],
  input[type='text']::placeholder {
    @include typography(copy);
  }

  input[type='text']::placeholder {
    color: color(neutral, 70);
    opacity: 1;
  }

  input[type='text']:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }

  input[type='text'].search__input--has-select {
    @include mq(m) {
      padding-right: 130px;
    }
  }
}

// Search Styles
.search {
  .blocks__block > & {
    margin-top: calc(-1 * var(--spacings-m));
  }

  @include max-width-container;
  @include get-grid-outer-gaps;

  &--align-top {
    margin-top: calc(-1 * var(--spacings-m)); // substract inner padding

    @include mq(m) {
      margin-top: calc(-1 * var(--spacings-l)); // substract inner padding
    }
  }
  // ************* Background - layer to close filters *************
  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: get-z-index('search-bg');
    cursor: pointer;
  }

  // ************* Bar *************
  &__bar {
    @include get-grid((), false);

    position: relative;
    display: block;
    // z-index: get-z-index('search-bar');
    padding: space(m);

    @include mq(m) {
      display: grid;
      padding: space(l) 0;
    }
  }

  &--has-headline &__bar {
    @include mq(m) {
      padding: space(s) 0 space(xl);
    }
  }

  &__headline.text {
    margin-bottom: space(s);

    @include mq(m) {
      grid-column: 2 / span 10;
    }
  }

  // ************* Form *************
  &__form {
    @include mq(m) {
      grid-column: 2 / span 10;
    }
  }

  // ************* Input *************
  &__default {
    @include mq(m) {
      display: flex;
    }
  }

  &__input {
    flex: 1;
    margin-bottom: space(xs);
    position: relative;

    @include mq(m) {
      margin-bottom: 0;
      margin-right: space(xs);
    }

    @include searchInput();
  }

  svg.search__input-icon {
    fill: color(neutral, 70);
    left: space(xs);
    position: absolute;
    top: 0.9rem;

    @include mq(m) {
      left: space(s);
    }
  }

  // ************* Select *************
  &__select.select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 115px;

    @include mq(s) {
      width: 120px;
    }

    .select__wrapper {
      height: 100%;
      width: 100%;
    }

    select {
      border: 0 none;
      height: 100%;
      width: 100%;
      border-radius: 0 space(xxs) space(xxs) 0;
      border-left: 2px solid color(neutral, 50);
      padding-right: space(l);
      padding-left: space(xxs);
      text-align: right;
    }

    select:hover {
      border-left: 2px solid color(neutral, 50);
    }

    select:focus-visible {
      border-left-color: transparent !important;
      outline-color: color(interaction);
      outline-offset: 0;
      outline-style: solid;
      outline-width: 2px;
    }
  }

  // ************* Submit Button *************
  &__button {
    border: 0 none;
    flex-shrink: 0;
    width: 100%;

    @include mq(m) {
      width: auto;
      margin-bottom: auto;
    }

    &:focus-visible {
      border-color: transparent;
      outline-color: color(interaction);
      outline-offset: 0;
      outline-style: solid;
      outline-width: 2px;
    }

    &[disabled] {
      background-color: color(accent);
      border-color: color(accent);
      color: color(neutral);
    }
  }

  // ************* Link *************
  &__link,
  &__link:focus {
    margin-top: space(s);

    @include mq(m) {
      margin-left: space(m);
      margin-top: space(xs);
      margin-bottom: auto;
    }

    @include mq(l) {
      margin-left: space(l);
    }

    &:focus-visible {
      @include mq(m) {
        margin-bottom: auto;
      }
    }
  }

  &__error.text {
    color: color(neutral, 100);
    margin-top: space(xs);
  }

  // ************* Filter Group *************
  &__filters {
    display: none;
    position: relative;
    margin-top: space(s);
    border-style: solid;
    border-width: 2px;
    border-radius: space(xxs);
    border-color: color(accent);

    @include mq(m) {
      border-radius: 0px;
      border-width: 0px;
      display: flex;
    }
  }

  &__filters--active {
    display: block;
  }

  &__filters-btn {
    width: 100%;
    background: transparent;
    color: color(neutral, 0);
    margin-top: space(s);

    &:hover,
    &:focus {
      color: color(neutral, 0);
    }
  }

  &__filter {
    flex-basis: 33%;
    position: relative;

    @include mq(m) {
      position: static;
    }
  }

  &__filter + &__filter {
    @include mq(m) {
      margin-left: space(xs);
    }
  }

  &__filter-btn {
    @extend .button;
    background-color: color(primary, 90);
    color: color(neutral, 0);
    width: 100%;
    justify-content: space-between;
    border-radius: 0px;
    border-width: 0px;
    padding: space(xs) space(s);

    @include mq(m) {
      border-width: 2px;
      border-radius: space(xxs);
      border-color: color(primary, 90);
      padding: space(xs) space(s);
    }

    @include mq(l) {
      padding: space(xs) space(m);
    }

    &[aria-expanded='true'] {
      background-color: color(primary, 30);
      border-color: color(primary, 30);

      svg {
        transform: rotate(180deg);
      }
    }

    &:focus-visible {
      border-color: color(interaction);
    }
  }

  &__filter--submitted &__filter-btn {
    @include mq(m) {
      border-color: color(accent);
    }
  }

  &__filter-btn:not(:last-child) {
    @include mq($until: m) {
      border-bottom: 1px solid color(primary, 70);
    }
  }

  &__filter-dialog {
    background-color: color(neutral, 0);
    color: color(neutral);
    width: 100%;
    overflow: hidden;
    max-height: 0vh;

    @include mq(m) {
      position: absolute;
      top: 66px;
      width: 29%;
      // z-index: get-z-index('dialog', 'search-bar');
      z-index: get-z-index('search-dialog');

      border-radius: space(xxs);
    }
  }

  &__filter-dialog--open {
    max-height: none;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  }

  &__filter-options-wrapper {
    position: relative;
    top: 0;

    @include mq(m) {
      max-height: 0;
    }
  }

  &__filter-options .range-slider__wrapper {
    margin-right: 25px;
    margin-left: 20px;

    .MuiSlider-root {
      margin-bottom: 0px;
    }

    .MuiSlider-mark,
    .MuiSlider-markLabel {
      display: none;
    }
  }

  &__filter-dialog--open &__filter-options-wrapper {
    overflow: auto;

    @include mq(m) {
      max-height: 265px; // TODO calculate max-height?
    }
  }

  &__filter-options,
  &__filter-options-footer {
    padding: space(s);
  }

  &__filter-checkbox:not(:last-child),
  &__filter-options .radio__item:not(:last-child) {
    margin-bottom: space(xs);
  }

  &__filter-options-footer {
    display: none;

    @include mq(m) {
      display: block;
      border-top: 1px solid color(neutral, 30);
    }
  }

  &__filter-options-btn {
    width: 100%;
  }

  // ************* Current Filters *************
  &__current {
    @include get-grid((), false);

    display: block;
    padding: space(s) 0 space(m);

    @include mq(s) {
      padding: space(s) space(m) space(m);
    }

    @include mq(m) {
      display: grid;
      padding: space(s) 0 space(l);
    }
  }

  &__current-filters {
    @include mq(m) {
      grid-column: 2 / span 10;
      display: flex;
    }
  }

  &__current-filters-group {
    display: flex;
    flex-wrap: wrap;

    @include mq(m) {
      margin-left: space(m);
    }
  }

  &__current-filters-headline.text {
    margin-top: space(xxs);
    flex-shrink: 0;
  }

  &__current-filter {
    display: flex;
    align-items: center;
    margin-top: space(xxs);

    &:not(:last-child) {
      margin-right: space(s);
    }
  }

  &__current-remove-btn {
    appearance: none;
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
    outline: 0;
    padding: 0;

    @include mq(m) {
      margin-left: space(xxs);
    }

    &:focus-visible svg {
      fill: color(interaction);
    }
  }

  &__current-remove-all {
    border-bottom: 2px solid currentColor;

    &:focus-visible {
      border-color: color(interaction);
    }
  }

  // *************** Variations ***************
  &--filled &__bar {
    background-color: color(primary, 70);
    color: color(neutral, 0);
  }

  &--filled &__bar &__link {
    border-bottom-color: color(neutral, 0);
    color: color(neutral, 0);
  }

  &--filled &__bar &__error {
    color: color(neutral, 0);
  }

  &--light &__bar {
    background-color: color(primary, 20);
    color: color(neutral);
  }
}

// *************** Print Styles ***************
@media print {
  .search {
    display: none;
  }
}
