.seo {
  position: relative;
  @include get-grid;
  @include max-width-container;

  margin-top: space(xl);
  margin-bottom: space(xl);

  @include mq(m) {
    margin-bottom: space(xxl);
    margin-top: space(xxl);
  }

  &__wrapper {
    grid-column: 1 / span 4;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 4 / span 6;
    }
  }
}

// *************** Print Styles ***************
@media print {
  .seo {
    display: none;
  }
}
