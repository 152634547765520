$mobileHeight: 80px;
$desktopHeight: 90px;
$tabletLogoSpacing: 120px;
$desktopLogoSpacing: 150px;

.navigation {
  min-height: $mobileHeight;

  @include mq(m) {
    min-height: $desktopHeight;
  }

  // ************* NAVIGATION WRAPPER *************
  &__wrapper {
    color: color(neutral, 90);
    position: relative;
    z-index: get-z-index('navigation');
  }

  &--open &__wrapper {
    background-color: color(neutral, 10);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    @include mq(m) {
      background-color: transparent;
    }

    &::before {
      @include mq(m) {
        background-color: color(neutral, 90);
        bottom: 0;
        content: '';
        cursor: pointer;
        display: block;
        left: 0;
        opacity: 0.9;
        position: fixed;
        right: 0;
        top: 0;
      }
    }
  }

  // ************* NAVIGATION CONTENT *************
  &__content {
    max-height: $mobileHeight;
    transition: max-height 300ms ease-in-out;

    @include mq(m) {
      max-height: $desktopHeight;
    }
  }

  // open content
  &--open &__content {
    height: auto;
    left: 0px;
    max-height: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: get-z-index('content', 'navigation');
    padding-bottom: space(xl);

    @include mq(m) {
      min-height: auto;
      position: relative;
      padding-bottom: 0;
    }
  }

  // ************* MAIN NAVIGATION *************
  &__main {
    @include max-width-container;

    @include mq(m) {
      @include get-grid-outer-gaps;

      align-items: center;
      display: flex;
      flex-direction: row;
      height: $desktopHeight;
      position: relative;
    }
  }

  // ************* INITAL (MOBILE) NAVIGATION *************
  &__initial {
    align-items: center;
    display: flex;
    height: $mobileHeight;
    justify-content: space-between;
    padding-left: get-grid-outer-gap('default');
    padding-right: get-grid-outer-gap('default');
    position: relative;

    @include mq(s) {
      padding-left: get-grid-outer-gap('s');
      padding-right: get-grid-outer-gap('s');
    }

    @include mq(m) {
      background-color: transparent;
      border-bottom: 0 none;
      color: inherit;
      display: block;
      height: auto;
      padding: 0;
      z-index: get-z-index('content-fg', 'navigation');
      width: $tabletLogoSpacing;
    }

    @include mq(l) {
      width: $desktopLogoSpacing;
    }
  }

  // ************* PRIMARY & SECONDARAY NAVIGATION *************
  &__primary,
  &__secondary {
    display: none;

    @include mq(m) {
      align-items: center;
      display: flex;
      flex-direction: row;
      z-index: get-z-index('content-fg', 'navigation');
    }
  }

  &__primary {
    @include mq(m) {
      flex-grow: 1;
    }

    @include mq(l) {
      grid-column: 2 / span 7;
    }

    & > * {
      &:not(:only-child):not(:last-child) {
        @include mq(m) {
          margin-right: space(s);
        }

        @media (min-width: 1024px) {
          margin-right: space(m);
        }

        @include mq(l) {
          margin-right: space(l);
        }
      }
    }
  }

  &__secondary {
    border-top: 1px solid color(neutral, 50);

    & > * {
      &:not(:only-child):not(:first-child) {
        @include mq(m) {
          margin-left: space(s);
        }

        @media (min-width: 1024px) {
          margin-left: space(m);
        }
      }
    }

    @include mq(m) {
      border-top: 0 none;
      margin-left: auto;
    }
  }

  &--open &__primary,
  &--open &__secondary {
    display: block;

    @include mq(m) {
      display: flex;
    }
  }

  // ************* LOGO *************
  &__logo {
    display: inline-block;

    @include mouseHoverState(0.8);

    @include keyboardFocus();

    @include mq(m) {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-50%);
    }
  }

  &__logo-svg {
    width: 67px;

    @include mq(m) {
      width: 72px;
    }

    @include mq(l) {
      width: 90px;
    }
  }

  // ************* MENU BTN *************
  &__menu-btn {
    appearance: none;
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
    display: inline-block;
    margin-left: auto;
    padding: 0;
    position: relative;
    z-index: get-z-index('initial-fg', 'navigation');

    @include mouseHoverState(0.8);

    @include keyboardFocus();

    @include mq(m) {
      display: none;
    }
  }

  &__menu-close {
    display: none;
  }

  &--open &__menu-close {
    display: block;
  }

  &--open &__menu-open {
    display: none;
  }

  // ************* MAIN BUTTON & LINK STYLES *************
  &__main-btn {
    appearance: none;
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include mq(m) {
      display: inline-flex;
      justify-content: flex-start;
      width: auto;
    }

    &[aria-expanded='true'] {
      background-color: color(neutral, 20);
      justify-content: flex-start;

      @include mq(m) {
        background-color: transparent;
      }

      svg {
        margin-right: space(s);
        order: -1;
        transform: rotate(180deg);

        @include mq(m) {
          margin-right: 0;
          order: 0;
        }
      }
    }
  }

  &__main-btn,
  &__main-link {
    align-items: center;
    display: flex;
    min-height: space(s);
    padding: space(s) get-grid-outer-gap('default');

    @include mouseHoverState();

    @include keyboardFocus(false, true);

    @include mq(s) {
      padding: space(s) get-grid-outer-gap('s');
    }

    @include mq(m) {
      padding: 0;
    }

    & + & {
      border-top: 1px solid color(neutral, 50);

      @include mq(m) {
        border-top: 0 none;
      }
    }
  }

  &__main-link--secondary {
    & + & {
      border-top: 0 none;
      padding-top: 0;
    }

    @include mq(m) {
      text-align: center;
      flex-direction: column;
    }
  }

  // hide text for small screens
  &__main-link--secondary &__secondary-text {
    @include mq(m) {
      display: none;
    }

    @include mq(l) {
      display: block;
    }
  }

  &__main-link--secondary &__main-link-icon {
    @include mq(m) {
      margin-right: 0;
    }

    @include mq(l) {
      margin-bottom: space(xxs);
    }
  }

  // Main Btn Variants
  &--default &__main-btn {
    @include mq(m) {
      color: color(primary, 70);
    }
  }

  &--filled &__main-btn {
    @include mq(m) {
      color: color(neutral, 0);
    }
  }

  &--light &__main-btn {
    @include mq(m) {
      color: color(primary, 80);
    }
  }

  &--has-image:not(.navigation--open) &__main-btn {
    @include mq(m) {
      color: color(neutral, 0);
    }
  }

  &--open-sub &__main-btn:not([aria-expanded='true']),
  &--open-sub &__main-link:not([aria-expanded='true']) {
    display: none;

    @include mq(m) {
      display: flex;
    }
  }

  // &__main-btn-icon,
  // &__main-link-icon {
  // }

  &__main-link-icon {
    margin-right: space(xxs);
  }

  &__main-btn-icon--mobile {
    display: block;

    @include mq(m) {
      display: none;
    }
  }

  &__main-btn-icon--desktop {
    display: none;
    margin-left: space(xxs);

    @include mq(m) {
      display: block;
    }
  }

  // ************* SUB NAVIGATION *************
  &__sub {
    background-color: color(neutral, 10);
    color: color(neutral, 90);
  }

  &__sub-list {
    @include max-width-container;
    @include get-grid();

    display: block;
    padding-bottom: space(m);
    padding-top: space(xs);

    &[hidden] {
      display: none;
    }

    @include mq(s) {
      display: block;
    }

    @include mq(m) {
      display: grid;
      padding-bottom: space(xl);
      padding-top: space(l);
    }
  }

  &__sub-column {
    display: block;

    @include mq(m) {
      grid-column: span 4;
      padding-right: space(l);
    }

    & + & {
      border-top: 1px solid color(neutral, 50);
      margin-top: space(xs);
      padding-top: space(xs);

      @include mq(m) {
        border-top: 0 none;
        margin-top: 0;
        padding-top: 0;
      }
    }
  }

  // grid alignment if only two columns
  &__sub-list[data-columns='2'] &__sub-column {
    &:first-child {
      @include mq(m) {
        grid-column: 1 / span 6;
      }

      @include mq(l) {
        padding-left: $desktopLogoSpacing;
      }
    }

    &:nth-child(2) {
      @include mq(m) {
        grid-column: 7 / span 6;
      }

      // @include mq(l) {
      //   grid-column: 6 / span 6;
      // }
    }
  }

  // ************* SUB BUTTON & LINK STYLES *************
  &__sub-group,
  &__sub-item {
    width: 100%;

    @include mouseHoverState();

    @include mq(m) {
      outline-offset: space(xxs);
    }

    @include keyboardFocus(false, true);
  }

  &__sub-group {
    display: inline-block;
    padding: space(xs) 0;

    @include mq(m) {
      padding: 0;
      margin-bottom: space(m);
    }

    &--centered {
      align-items: center;
      display: flex;
    }

    .text {
      display: block;
    }
  }

  &__sub-icon {
    height: space(l);
    margin-right: space(xs);
    width: space(l);

    @include mq(s) {
      height: 60px;
      margin-right: space(s);
      width: 60px;
    }
  }

  &__sub-item {
    display: inline-block;
    padding: space(xs) 0;

    @include mq(m) {
      margin-bottom: space(s);
      padding: space(xxs) 0;
    }
  }

  &__sub-item--highlighted {
    @include mq(m) {
      margin-bottom: space(m);
    }
  }

  &__sub-img {
    margin-top: space(xs);
    max-width: 450px;
    width: 100%;

    @include mq(m) {
      display: inline-block;
    }
  }

  // *************** Variations ***************
  &--default &__wrapper {
    @include mq(m) {
      border-bottom: 1px solid color(primary, 20);
      color: color(primary, 70);
    }
  }

  &--filled &__wrapper {
    &::before {
      content: '';
      width: 100%;
      height: 80px;
      background: linear-gradient(
        to top,
        transparent 0%,
        rgba(0, 0, 0, 0.3) 100%
      );
    }
    @include mq(m) {
      border-bottom: 1px solid color(neutral, 70);
      color: color(neutral, 0);
    }
  }

  &--light &__wrapper {
    @include mq(m) {
      border-bottom: 1px solid color(primary, 20);
      color: color(primary, 80);
    }
  }

  &--has-image:not(.navigation--open) &__wrapper {
    border-bottom: 1px solid color(neutral, 70);
    @include mq(m) {
      color: color(neutral, 0);
    }
  }

  &--default &__initial {
    border-bottom: 1px solid color(primary, 20);
    color: color(primary, 70);
  }

  &--filled &__initial {
    border-bottom: 1px solid color(neutral, 70);
    color: color(neutral, 0);
  }

  &--light &__initial {
    border-bottom: 1px solid color(primary, 20);
    color: color(primary, 80);
  }

  &--default &__initial,
  &--filled &__initial,
  &--light &__initial {
    @include mq(m) {
      background-color: transparent;
      border-bottom: 0 none;
      color: inherit;
    }
  }

  &--has-image:not(.navigation--open) &__initial {
    background-color: transparent;
    border-bottom: 1px solid color(neutral, 70);
    color: color(neutral, 0);

    @include mq(m) {
      background-color: transparent;
      border-bottom: 0 none;
      color: inherit;
    }

    // mobile gradient
    &:before {
      background: linear-gradient(
        to top,
        transparent 0%,
        rgba(0, 0, 0, 0.3) 100%
      );
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: get-z-index('initial-bg', 'navigation');

      @include mq(m) {
        display: none;
      }
    }
  }

  &--filled:is(.navigation--open) &__initial {
    background-color: color(primary, 50);
  }

  &--light:is(.navigation--open) &__initial {
    background-color: color(primary, 10);
  }

  &--default:is(.navigation--open) &__content {
    @include mq(m) {
      background-color: color(neutral, 0);
    }
  }

  &--filled:is(.navigation--open) &__content {
    @include mq(m) {
      background-color: color(primary, 50);
    }
  }

  &--light:is(.navigation--open) &__content {
    @include mq(m) {
      background-color: color(primary, 10);
    }
  }

  &--default &__menu-btn {
    color: color(primary, 70);
  }

  &--filled &__menu-btn {
    color: color(neutral, 0);
  }

  &--light &__menu-btn {
    color: color(primary, 80);
  }

  &--has-image:not(.navigation--open) &__menu-btn {
    color: color(neutral, 0);
  }

  // ************* Gradients *************

  // desktop gradient
  &--has-image:not(.navigation--open) &__main {
    &:before {
      @include mq(m) {
        background: linear-gradient(
          to top,
          transparent 0%,
          rgba(0, 0, 0, 0.3) 100%
        );
        bottom: 0;
        content: '';
        display: block;
        left: 50%;
        pointer-events: none;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: get-max-width('images');
        z-index: get-z-index('content-bg', 'navigation');
      }
    }
  }
}

// *************** Print Styles ***************
@media print {
  .navigation {
    &__menu-btn,
    &__primary,
    &__secondary {
      display: none;
    }
  }
}
