.questionaire {
  /********************** STATUS **********************/
  &__status-bar {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background-color: color('neutral', 50);
    }
  }

  &__status-progress {
    position: relative;
    height: 4px;
    border-radius: 4px;
    background-color: color('success');
    transition: width 200ms ease-in-out;
    margin-bottom: space(xxs);
  }

  &__status-text {
    display: flex;
    align-items: center;
  }

  &__status-text--active {
    color: color('success');
  }

  &__status-text-checkmark {
    visibility: hidden;
  }

  &__status-text--active &__status-text-checkmark {
    visibility: visible;
    fill: color('success');
  }

  /********************** HEADER **********************/
  &__header {
    padding-bottom: space(m);
    margin-top: space(m);
  }

  &__header-title,
  &__header-copy {
    max-width: 700px;
  }

  &__header-copy .richtext__wrapper * {
    font-size: 14px;
  }

  &__header-copy .richtext__wrapper p + p {
    margin-top: space(xs);
  }

  &__header-title.text {
    margin-bottom: space(xs);
  }

  /********************** CTA CONTAINER **********************/

  &__cta-container {
    margin-top: space(m);

    @include mq(m) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &__cta-container-info {
    margin-right: space(s);
  }

  &__cta-container-info-and-text {
    display: flex;

    .icon {
      min-width: 24px;
      min-height: 24px;
      margin-top: space(xxs);
      margin-right: space(xxs);
    }
  }

  &__cta-container-info-text.text {
    margin-top: space(xxs);
  }

  &__cta-container-ctas {
    margin-top: space(xs);

    @include mq(s) {
      margin-left: auto;
      display: flex;
      flex-direction: row;
    }

    @include mq(m) {
      margin: 0;
    }

    button + button {
      margin-top: space(xs);

      @include mq(s) {
        margin: 0;
        margin-left: space(xs);
      }
    }
  }

  &__cta-container-error.text {
    color: color(warning);
  }

  /********************** PAGES **********************/
  &__page {
    padding-bottom: space(xs);

    @include mq(m) {
      padding: 0;
      display: flex;
      align-items: center;
    }

    &:not(:last-child) {
      border-bottom: 1px solid color(neutral, 50);
    }
  }

  &__page-button {
    padding: space(xxs) 0 0 0;
    @include mq(m) {
      padding: space(xxs) space(xs);
      margin-right: auto;
    }
  }

  /********************** QUESTIONS **********************/
  &__question {
    padding: space(m) 0;
    border-top: 1px solid color(neutral, 50);

    .radio__label,
    .input__label {
      margin-bottom: space(xs);
    }

    .radio__option-range .text.text--form-label {
      @include typography(copy-small);
    }
  }

  a {
    color: color(neutral);
    text-decoration: underline;

    @include mouseHoverState();
  }

  &__dialog-content {
    display: flex;
    gap: space(xs);

    .icon {
      fill: color(success);
    }
  }
}
