// TODO use "has-grid" modifier for grid css
.block-media {
  position: relative;
  @include get-grid;

  &__figure {
    grid-column: 1 / span 4;
    position: relative;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  &--has-text &__figure {
    grid-column: 1 / span 3;
  }

  &--text-is-left &__figure {
    @include mq(s) {
      grid-column: 5 / span 2;
    }

    @include mq(m) {
      grid-column: 9 / span 4;
    }
  }

  &--text-is-right &__figure {
    @include mq(s) {
      grid-column: 1 / span 2;
    }

    @include mq(m) {
      grid-column: 1 / span 4;
    }
  }

  &__text {
    grid-column: 1 / span 4;
    order: 1;
    padding-top: space(l);

    @include mq(m) {
      order: 0;
      padding-top: 0;
    }
  }

  &--text-is-left &__text {
    @include mq(s) {
      grid-column: 1 / span 4;
      padding-right: space(m);
    }

    @include mq(m) {
      grid-column: 4 / span 5;
    }
  }

  &--text-is-right &__text {
    order: 1;

    @include mq(s) {
      grid-column: 3 / span 4;
      padding-left: space(m);
    }

    @include mq(m) {
      grid-column: 5 / span 5;
    }
  }

  // &__caption {
  //   padding-top: space(xs);
  // }

  // *************** Animation ***************
  // &[data-animatable-module].block-media--text-is-left &__figure {
  //   // inital style

  //   opacity: 0;
  //   transform: translateX(30px);
  //   // animation transition

  //   transition: opacity bezier('entrance')
  //       time('m'),
  //     transform bezier('entrance') time('m');
  //   // in viewport style
  //   &.is-in-viewport {
  //     opacity: 1;
  //     transform: translateX(0);
  //   }
  // }

  // &[data-animatable-module].block-media--text-is-right &__figure {
  //   // inital style

  //   opacity: 0;
  //   transform: translateX(-30px);
  //   // animation transition

  //   transition: opacity bezier('entrance')
  //       time('m'),
  //     transform bezier('entrance') time('m');
  //   // in viewport style
  //   &.is-in-viewport {
  //     opacity: 1;
  //     transform: translateX(0);
  //   }
  // }
}
