.intro {
  z-index: get-z-index('intro');

  &--has-image {
    color: color(neutral, 0);
  }

  &__wrapper {
    position: relative;
  }

  .blocks,
  .blocks__block {
    margin-bottom: 0;
  }

  /* ---------- Background ---------- */
  &__background {
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;

    svg {
      fill: color(neutral);
      height: 100%;
      opacity: 0.03;
      position: relative;
      top: 50%;
      transform: translateX(-75%) translateY(-50%);
      width: auto;

      @include mq(m) {
        top: 50%;
        transform: translateX(-70%) translateY(-50%);
        width: auto;
      }
    }
  }

  &__background-inner {
    @include max-width-container('images');

    height: 100%;
    overflow: hidden;
    position: relative;
  }

  &--has-image &__background-inner {
    &:after {
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 85%
      );
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__bg-image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  /* ---------- Content ---------- */
  &__content {
    @include max-width-container;
    @include get-grid;

    position: relative;
    z-index: get-z-index('content', 'intro');
  }

  &--has-image &__content {
    aspect-ratio: 2.5/1; // min-height equals 16/10
    grid-template-rows: auto 1fr;
  }

  /* ---------- Breadcrumb ---------- */
  &__breadcrumb {
    align-items: center;
    color: color(primary, 20);
    display: flex;
    flex-wrap: wrap;
    grid-column: 1 / span 4;
    margin-top: space(xs);
    position: relative;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 1 / span 12;
    }
  }

  &__breadcrumb-svg {
    margin: 0 space(xxs);
  }

  &__breadcrumb-current,
  &__breadcrumb-link {
    align-items: center;
    display: flex;
    height: space(m);
    max-width: 100%;

    span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__breadcrumb-link-inner {
    line-height: 1;
  }

  /* ---------- Main ---------- */
  &__main {
    grid-column: 1 / span 4;
    padding: space(l) 0 space(s);

    @include get-grid(
      (
        default: 4,
        m: 8
      ),
      false
    );

    @include mq(s) {
      grid-column: 1 / span 4;
    }

    @include mq(m) {
      grid-column: 2 / span 8;
      padding: space(xl) 0 space(m);
    }

    @include mq(l) {
      padding: space(xxl) 0 space(l);
    }
  }

  &__main:last-child {
    padding-bottom: space(l);

    @include mq(m) {
      padding-bottom: space(xl);
    }

    @include mq(xl) {
      padding-bottom: space(xxl);
    }
  }

  &--align-center &__main {
    text-align: center;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  &--has-image &__main {
    margin: auto 0;
  }

  &--has-search &__main {
    padding-bottom: calc(var(--spacings-l) + var(--spacings-s));

    @include mq(m) {
      padding-bottom: calc(var(--spacings-xxl) + var(--spacings-m));
    }
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: space(s);
  }

  &__favorite {
    margin-left: space(xs);

    @include mq(m) {
      margin-left: space(m);
    }
  }

  &__headline.text {
    display: block;
    grid-column: 1 / span 4;
    position: relative;

    @include mq(m) {
      grid-column: 1 / span 8;
    }
  }

  &__headline:not(:last-child) {
    padding-bottom: space(m);

    @include mq(m) {
      padding-bottom: space(l);
    }
  }

  &__headline:first-child:not(:last-child) {
    padding-bottom: space(s);

    @include mq(m) {
      padding-bottom: space(m);
    }
  }

  &--align-center &__headline.text:last-child:after {
    display: none;
  }

  &__header,
  &__copy,
  &__ctas {
    grid-column: 1 / span 4;

    @include mq(m) {
      grid-column: 1 / span 6;
    }
  }

  &--align-center &__header,
  &--align-center &__copy,
  &--align-center &__ctas {
    @include mq(m) {
      grid-column: 2 / span 6;
    }
  }

  &__ctas {
    padding-top: var(--spacings-m);
  }

  /* ---------- Details ---------- */
  &__details {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 4;
    padding: space(m) 0 space(l);
    position: relative;

    @include mq(s) {
      flex-direction: row;
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 2 / span 10;
      padding: space(l) 0 space(xl);
    }

    @include mq(xl) {
      padding: space(l) 0 space(xxl);
    }

    &:before {
      background: color(neutral, 0);
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      top: 0;
      width: 66px;

      @include mq(m) {
        width: 97px;
      }
    }
  }

  &__detail {
    align-items: center;
    display: flex;
    margin-bottom: space(s);

    @include mq(s) {
      margin-bottom: 0;
      padding-left: space(m);
    }

    @include mq(l) {
      padding-left: space(xxl);
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__detail-icon {
    height: space(s);
    margin-right: space(xs);
    width: space(s);
  }

  // *************** Variations ***************
  &--default &__wrapper {
    background-color: transparent;
    color: color(primary, 70);
  }

  &--filled &__wrapper {
    background-color: color(primary);
    color: color(neutral, 0);
  }

  &--light &__wrapper {
    background-color: color(primary, 10);
    color: color(primary, 80);
  }

  &--light:is(.intro--has-image) &__wrapper {
    color: color(neutral, 0);
  }

  &--filled:is(.intro--has-image) &__wrapper {
    background-color: color(primary, 70);
  }

  &--filled &__background svg {
    opacity: 0.1;
  }

  &--default &__breadcrumb,
  &--light &__breadcrumb {
    color: color(primary, 50);
  }

  &--filled &__breadcrumb {
    color: color(primary, 20);
  }

  &--has-image &__breadcrumb {
    color: color(neutral, 0);
  }

  &--default:not(.intro--has-search) &__main {
    padding-bottom: 0;
  }

  // *************** Animation ***************
  // --- LINK ---
  // inital animation style & transition
  &[data-animatable-module] &__headline {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity bezier('highlight') time('xs'),
      transform bezier('highlight') time('xs');
  }
  // in viewport style
  &[data-animatable-module].is-in-viewport &__headline {
    opacity: 1;
    transform: translateY(0);
  }

  // --- OVERLINE / COPY ---
  // inital animation style & animation transition
  &[data-animatable-module] &__header,
  &[data-animatable-module] &__copy,
  &[data-animatable-module] &__ctas {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity bezier('entrance') time('l') time(('s', 's')),
      transform bezier('entrance') time('l') time(('s', 's'));
  }
  // in viewport style
  &[data-animatable-module].is-in-viewport &__header,
  &[data-animatable-module].is-in-viewport &__copy,
  &[data-animatable-module].is-in-viewport &__ctas {
    opacity: 1;
    transform: translateY(0);
  }

  // --- DETAILS ---
  // inital animation style & animation transition
  &[data-animatable-module] &__details {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity bezier('entrance') time('m') time(('m', 's', 's')),
      transform bezier('entrance') time('m') time(('m', 's', 's'));
  }
  // in viewport style
  &[data-animatable-module].is-in-viewport &__details {
    opacity: 1;
    transform: translateY(0);
  }
}

// *************** Print Styles ***************
@media print {
  .intro {
    &__breadcrumb,
    &__background {
      display: none;
    }

    &__main > * {
      &:not(:last-child) {
        margin-bottom: 10pt;
      }
    }

    &__details {
      padding-top: 0pt;
      padding-bottom: 0pt;
    }

    &__detail {
      margin-bottom: 10pt;
    }
  }
}
