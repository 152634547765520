.sortable {
  &__lists {
    display: flex;
    flex-direction: column;
    gap: 0;

    @include mq(m) {
      gap: space(s);
      flex-direction: row;
    }
  }

  &__list {
    margin-top: space(s);

    @include mq(m) {
      min-height: 100%;
      width: 50%;
    }
  }

  &__list:nth-child(1) {
    [data-js-element='sortable__orderItems'] {
      display: none;
    }
  }

  &__list:nth-child(2) {
    .icon {
      display: none;
    }
    min-height: 250px;
    border: 2px solid color(neutral, 30);
    background: color(neutral, 20);
  }

  legend {
    margin-bottom: space(xxs);
  }

  &__item {
    cursor: grab;
    background: color(neutral, 10);
    padding: space(xs);
    margin-bottom: space(xs);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    pointer-events: all;

    [data-js-element='sortable__orderItems'] {
      margin-right: space(xs);
    }
    .icon {
      margin-right: space(xs);
      min-width: 24px;
      min-height: 24px;
    }
    .text {
      user-select: none;
      pointer-events: none;
    }
  }
  &__item:last-child {
    margin-bottom: 0;
  }

  &__item--chosen {
    cursor: grabbing;
  }

  &__item--drag-background {
    background: color(primary, 30);
    color: white;

    [data-js-element='sortable__orderItems'] {
      opacity: 0;
    }
    .icon {
      filter: invert(1);
    }
  }
}
