// TODOs
// 1. remove deprecated scripts & css & data attributes
// 2. where to put animation css? where to put animation logic?
// 3. visualize animation tokens in storybook
// 4. get css tokens via sass functions?

// Sync these styles with <noscript> Style Tag in Head
// 1. For Storybook see: frontend/.storybook/preview-head.html
// 2. Also add to layout template (e.g. layout.default.twig)

@mixin reducedMotionReset() {
  [data-animatable-module],
  [data-animatable-element] {
    @media (prefers-reduced-motion) {
      opacity: 1 !important;
      transform: none !important;
    }
  }
}

@mixin scaleOnHover() {
  transition: transform bezier('entrance') time('m');

  &:hover {
    transform: scale(1.025);
  }
}

@mixin scaleImageOnHover() {
  transition: transform bezier('entrance') time('m');

  a:hover & {
    transform: scale(1.05);
  }
}

@mixin animationTokens() {
  --animation-bezier-entrance: cubic-bezier(0.44, 0.4, 0.05, 1.03);
  --animation-bezier-highlight: cubic-bezier(0.23, 0.09, 0.05, 1.07);
  // --animation-time-xs: 0.2s;
  // --animation-time-s: 0.6s;
  // --animation-time-l: 1.2s;
}

// $bezier: (
//   'entrance': cubic-bezier(0.44, 0.4, 0.05, 1.03),
//   'highlight': cubic-bezier(0.23, 0.09, 0.05, 1.07)
// );

$timings: (
  'xxs': 0.1,
  'xs': 0.2,
  's': 0.4,
  'm': 0.6,
  'l': 1
);

@function bezier($key) {
  @return var(--animation-bezier-#{$key});
}

@function time($timing, $factor: 0) {
  $time: 0;

  // get $time

  @if type-of($timing) == string {
    // 1. if $timing is single value
    $time: map-get($timings, $timing);
  } @else if type-of($timing) == list {
    // 2. else if $timing is array of values
    // than add up values in array to a total sum
    // e.g. to enable a delay based on previous animations

    @each $value in $timing {
      $time: $time + map-get($timings, $value);
    }
  }

  // multiply $time by factor - optional
  // e.g. to stagger animations

  @if $factor > 0 {
    $time: $time * $factor;
  }

  @return #{$time}s;
}
