$mobileHeight: 80px;
$desktopHeight: 90px;
$tabletLogoSpacing: 120px;
$desktopLogoSpacing: 150px;

.intro-compact {
  z-index: get-z-index('intro-neu');
  min-height: $mobileHeight;

  @include mq(m) {
    min-height: $desktopHeight;
  }

  &__wrapper {
    position: relative;
    background-color: color(primary);
    color: color(neutral, 0);
  }

  &__content {
    @include max-width-container;
    position: relative;
    // z-index: get-z-index('content', 'intro-neu');
  }
  &__main {
    max-height: $mobileHeight;
    border-bottom: 1px solid color(primary, 70);
    @include get-grid;
    @include get-grid-outer-gaps;
    grid-template-rows: auto 1fr;

    @include mq(m) {
      max-height: $desktopHeight;
    }
  }

  &__logo {
    display: inline-block;
    flex-shrink: 0;

    @include keyboardFocus();

    grid-column: 1 / span 4;
    padding: space(s) 0;
    text-align: left;
  }

  &__logo-svg {
    width: 67px;

    @include mq(m) {
      width: 72px;
    }
    @include mq(l) {
      width: 90px;
    }
  }

  &__cancel {
    grid-column: 11 / span 3;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: right;
    color: color(primary, 20);
  }

  &__main-sub {
    @include get-grid;
  }

  &__vacancy {
    grid-column: 1 / span 12;
    padding-top: space(m);
    padding-bottom: space(m);
  }
}

@media print {
  .intro-compact {
    &__cancel {
      display: none;
    }
  }
}
