.internalEvaluation {
  &__boxplots {
    &__text.text {
      margin: space(xs) 0;
    }

    &__text .text--red {
      color: color(warning);
    }

    &__headline.text {
      margin-top: space(m);
    }

    &__chart,
    &__chart-median {
      margin-top: space(m);
      width: 100%;
      background: color(neutral, 20);
      padding: space(l);

      @media print {
        break-inside: avoid;
        padding: 0;
      }

      .chart,
      figure {
        width: 100%;
      }

      .chart__canvas-wrapper {
        height: 550px;
      }
    }

    &__charts {
      width: 100%;
    }

    &__chart {
      display: flex;
      flex-direction: column;

      @include mq(m) {
        flex-direction: row;

        figure {
          width: 25%;
        }
      }

      @media print {
        flex-direction: row;

        figure {
          width: 25%;
        }
      }
    }

    &__chart-median {
      .chart__canvas-wrapper {
        padding-top: space(xxs);
        height: 85px;
      }
    }
  }

  hr {
    border: 1px solid color(neutral, 50);
    margin-top: space(m);
    margin-bottom: space(s);
  }

  &__value-consensus {
    &__chart {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media print {
        break-inside: avoid;
      }

      @include mq(m) {
        grid-template-columns: 200px auto 200px;
      }

      &-question {
        text-align: center;
        grid-column: 1 / span 2;

        @include mq(m) {
          grid-column: 1 / span 3;
        }

        &__icons {
          display: flex;
          justify-content: center;
        }
      }

      &-from {
        padding-right: space(s);
        grid-row: 2;

        @include mq(m) {
          grid-column: 1;
        }
      }

      &-figure {
        align-items: center;
        display: flex;
        flex-direction: column;
        grid-column: 1 / span 2;
        grid-row: 3;

        @include mq(m) {
          grid-column: 2;
        }

        .chart {
          &__canvas-wrapper {
            height: 140px;
          }
        }
      }

      &-to {
        grid-row: 2;
        padding-left: space(s);
        text-align: right;

        @include mq(m) {
          grid-column: 3;
          text-align: left;
        }
      }
    }
  }

  &__percentage-analysis {
    &__text.text {
      margin: space(xs) 0;
    }

    &__headline.text {
      margin-top: space(m);
    }

    &__chart-figure {
      margin-top: space(s);

      .chart {
        margin-top: space(s);
        &__canvas-wrapper {
          height: 140px;
        }
      }
    }
  }
}
