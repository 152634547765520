.text {
  color: inherit;
  margin: 0;

  // TODO how to check if all typographies are definied in every theme

  @each $name, $value in get-typography-theme('default') {
    &--#{$name} {
      @include typography(#{$name});
    }
  }

  &--overline {
    text-transform: uppercase;
  }

  &--underline {
    text-decoration: underline;
  }

  &--form-hint {
    text-transform: uppercase;
  }

  &--bold,
  b,
  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  &--no-transform {
    text-transform: none;
  }
}
