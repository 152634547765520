// TODO use "has-grid" modifier for grid css
.teaser-hero {
  display: block;
  position: relative;
  &__grid {
    @include get-grid;
    padding: 0;
    position: relative;
  }

  &__wrapper {
    position: relative;
    background-color: color(neutral, 10);
    grid-column: 1 / span 4;
    grid-row: 1 / span 2;
    z-index: get-z-index('wrapper', 'teaser-hero');
    overflow: hidden;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 1 / span 11;
      grid-row: 1 / span 1;
    }
  }

  &__svg {
    position: absolute;
    right: 0;
    fill: color(neutral);
    height: 600px;
    opacity: 0.03;
    top: 50%;
    transform: translateX(70%) translateY(-50%);
    width: auto;

    @include mq(m) {
      transform: translateX(60%) translateY(-50%);
    }

    @include mq(l) {
      transform: translateX(55%) translateY(-50%);
    }
  }

  &--filled svg {
    fill: color(neutral, 0);
  }

  &__text {
    grid-column: 1 / span 4;
    grid-row: 1 / span 1;
    margin-bottom: space(m);
    margin-top: space(l);
    padding: 0 space(m);

    @include mq(s) {
      grid-column: 1 / span 4;
      margin-bottom: space(l);
      padding-right: 0;
    }

    @include mq(m) {
      grid-row: 1 / span 1;
      margin: space(l) 0;
      padding: space(s) 0;
      display: flex;
      flex-direction: column;
      grid-column: 1 / span 6;
      justify-content: center;
      padding: space(s) 0 space(s) space(l);
    }

    @include mq(l) {
      padding: space(s) space(s) space(s) space(xl);
    }
  }

  &__image {
    grid-column: 1 / span 4;
    grid-row: 2 / span 1;
    margin-bottom: space(l);
    margin-right: calc(-1 * #{get-grid-outer-gap('default')});
    padding-left: space(m);

    img {
      @include scaleImageOnHover();
    }

    @include mq(s) {
      grid-column: 1 / span 6;
      margin-right: calc(-1 * #{get-grid-outer-gap('s')});
    }

    @include mq(m) {
      align-items: center;
      display: flex;
      grid-column: 8 / span 5;
      grid-row: 1 / span 1;
      margin: space(l) 0;
      padding-left: 0;
    }
  }

  &__headline.text {
    margin-bottom: space(m);
  }

  &__label {
    align-items: center;
    border-bottom: 2px solid color(neutral);
    display: inline-flex;
    margin-top: space(m);
    transition: color 200ms ease-in-out, border-bottom-color 200ms ease-in-out;

    @include mq(m) {
      margin-top: auto;
      padding-top: space(l);
    }

    &:hover {
      border-bottom-color: color(neutral, 80);
      color: color(neutral, 80);
    }
  }

  &__icon {
    left: 0;
    margin-left: space(xxs);
    position: relative;
    transition: left 200ms ease-in-out;
  }

  &:hover &__icon {
    left: space(xxs);
  }

  // *************** Variations ***************
  &--filled &__wrapper {
    background-color: color(primary, 70);
  }

  &--light &__wrapper {
    background-color: color(primary, 10);
  }

  &--filled &__headline.text,
  &--filled &__copy.text,
  &--filled &__label {
    border-bottom-color: color(neutral, 0);
    color: color(neutral, 0);
  }

  &--light &__headline.text,
  &--light &__copy.text,
  &--light &__label {
    border-bottom-color: color(primary, 90);
    color: color(primary, 90);
  }

  // *************** Animation ***************
  // --- WRAPPER ---
  // inital animation style & transition
  &[data-animatable-module] &__wrapper {
    opacity: 0;
    position: relative;
    transform: translateY(20px);
    transition: opacity bezier('entrance') time('l'),
      transform bezier('entrance') time('l');
  }
  // in viewport style
  &[data-animatable-module].is-in-viewport &__wrapper {
    opacity: 1;
    transform: translateY(0);
  }

  // --- IMAGE ---
  // inital animation style & transition
  &[data-animatable-module] &__image {
    opacity: 0;
    position: relative;
    transform: translateY(10px);
    transition: opacity bezier('highlight') time('m') time(('l', 'xs')),
      transform bezier('highlight') time('m') time(('l', 'xs'));
  }
  // in viewport style
  &[data-animatable-module].is-in-viewport &__image {
    opacity: 1;
    transform: translateY(0px);
  }

  // --- TEXT ---
  // inital animation style & transition
  &[data-animatable-module] &__text {
    opacity: 0;
    position: relative;
    transform: translateY(10px);
    transition: opacity bezier('entrance') time('s') time(('l', 's')),
      transform bezier('entrance') time('s') time(('l', 's'));
  }
  // in viewport style
  &[data-animatable-module].is-in-viewport &__text {
    opacity: 1;
    transform: translateY(0px);
  }
}
