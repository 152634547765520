.report {
  .chart {
    margin-top: space(m);
    @include mq(m) {
      width: 75%;
    }
  }

  .table,
  .chart {
    margin-top: space(l);

    @include mq(l) {
      margin-top: space(xl);
    }
  }

  &__download-btn {
    margin-left: auto;
  }
}
