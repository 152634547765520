.form {
  position: relative;

  &--has-background {
    background-color: color(neutral, 20);
  }

  &--has-grid {
    @include get-grid-outer-gaps;
    display: block;
    @include get-grid((), false);

    @include mq(s) {
      display: block;
    }

    @include mq(m) {
      display: grid;
    }
  }

  &--has-grid &__form,
  &--has-grid &__secondary {
    grid-column: span 4;

    @include mq(s) {
      grid-column: span 6;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  &--has-background &__form {
    padding: space(l) space(m);

    @include mq(s) {
      padding: space(l);
    }

    @include mq(m) {
      padding: space(xl) 0;
    }
  }

  &__field,
  &__copy,
  &__link {
    margin-bottom: space(m);
  }

  &__headline,
  &__headline.text {
    display: block;
    margin-bottom: space(m);

    &:not(:first-child) {
      margin-top: space(l);
    }
  }

  &__row {
    @include get-grid(
      (
        default: 4,
        m: 8
      ),
      false
    );
  }

  &__column {
    @include mq($until: m) {
      grid-column: 1 / span 4;
    }

    @include mq(m) {
      @for $i from 1 through 12 {
        &[data-form-columns='#{$i}'] {
          grid-column-end: span #{$i};
        }
      }
    }
  }

  &__mandatory.text {
    display: block;
    margin-bottom: space(s);
    margin-top: space(m);
  }

  &__ctas {
    display: flex;
  }

  &__secondary {
    display: flex;
    align-items: center;
    margin-right: space(s);
  }

  &__secondary-seperator.text {
    margin-left: space(s);
  }

  &__secondary-cta {
    display: inline-flex;
  }
}

// *************** Print Styles ***************
@media print {
  .form {
    display: none;
  }
}
