@import '../search/search';

.autosuggest {
  @include searchInput();

  &__container {
    padding: space(xs) space(xs) 0;

    @include mq(m) {
      padding: space(xs) space(s) 0;
    }
  }

  &__consent-link {
    margin-top: space(xxs);
  }

  &__consent-details {
    background: transparent;
    margin-top: space(xxs);
    appearance: none;
    border: 0;
    padding: 0;
    cursor: pointer;
  }

  &__consent-btn {
    margin-top: space(xs);
  }

  .react-autosuggest__container--open input[type='text'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .autosuggest__no-consent,
  .react-autosuggest__suggestions-container--open {
    width: 100%;
    position: absolute;
    top: 52px;
    left: 0;
    z-index: 2;
    border-top: 1px solid color(neutral, 50);
    border-bottom-right-radius: space(xxs);
    border-bottom-left-radius: space(xxs);
    background: white;
    color: black;
    max-height: 265px;
    overflow-y: auto;
    padding-bottom: space(xs);
    box-shadow: 2px 2px 26px 0 rgba(0, 0, 0, 0.1);
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: space(xs);

    @include mq(m) {
      padding: space(xs) space(s);
    }

    & > div {
      display: flex;
    }
  }

  .react-autosuggest__suggestion-icon {
    margin-right: space(xs);
    fill: color(neutral, 70);
    flex-shrink: 0;
  }
}
