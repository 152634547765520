.dialog {
  bottom: 0px;
  left: 0px;
  overflow-y: auto;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: get-z-index('dialog');

  @include mq(m) {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__wrapper {
    @include mq(s) {
      @include get-grid;

      grid-template-rows: space(l) 1fr space(l);
    }

    // display: flex;
    // min-height: 100%;

    @include mq(m) {
      // align-items: center;
      // margin: space(l) auto;
      // min-height: calc(100vh - (2 * #{space(l)}));
    }

    /* workaround for flexbugs #3 (IE does not align-items with min-height) */
    // &::before {
    //   @include mq(m) {
    //     content: '';
    //     display: block;
    //     height: calc(100vh - (2 * #{space(l)}));
    //   }
    // }
  }

  &__header {
    margin-bottom: space(m);

    @include mq(s) {
      padding-right: space(xl);
    }

    @include mq(m) {
      margin-bottom: space(l);
    }
  }

  &__content {
    background: color(neutral, 10);
    flex: 1 1 0%;
    height: 100%;
    overflow-y: auto;
    padding: space(l) space(m);
    position: relative;

    @include mq(s) {
      grid-column: 1 / span 6;
      grid-row: 2 / span 1;
      border-radius: space(xxs);
    }

    @include mq(m) {
      grid-column: 2 / span 10;
      padding: space(xl) space(xxl);
    }
  }

  &__close {
    position: absolute;
    right: space(xs);
    top: space(xs);

    @include mq(s) {
      right: space(m);
      top: space(m);
    }

    @include mq(m) {
      right: space(l);
      top: space(l);
    }

    svg.icon {
      display: block;
      height: space(s);
      width: space(s);
    }
  }

  // overwrite blocks css
  .blocks {
    margin: 0;

    @include mq(m) {
      margin-bottom: space(l);
    }
  }

  .blocks__block:first-of-type {
    margin-top: 0;
  }

  .blocks__block:last-of-type {
    margin-bottom: 0;
  }
}
