.list {
  &__wrapper {
    @include max-width-container;
    @include get-grid;
  }

  &__lead-in.text {
    margin-top: space(xs);
  }

  &__header {
    grid-column: 1 / span 4;
    text-align: center;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  &__content {
    grid-column: 1 / span 4;
    margin-top: space(l);

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 1 / span 12;
      // margin-top: space(l);
    }
  }

  &__ul,
  &__ol {
    @include mq(s) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__item {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: space(l);

    @include mq(s) {
      flex-shrink: 0;
      margin-bottom: space(l);
      padding: 0 space(s);
      width: 50%;
    }

    @include mq(m) {
      padding: 0 space(m);
      width: 33%;
    }
  }

  &__item-icon {
    height: space(xl);
    margin-bottom: space(s);
    width: space(xl);

    @include mq(s) {
      height: 80px;
      width: 80px;
    }
  }

  &__item-headline {
    margin-bottom: space(xxs);
  }

  &__item-copy.richtext {
    margin-top: space(s);
  }

  &__item-headline,
  &__item-copy.richtext {
    text-align: center;
  }

  &__cta {
    display: flex;
    justify-content: center;
    margin-top: space(l);

    @include mq(s) {
      margin-top: space(xs);
    }
  }

  // *************** Variations ***************
  &--default {
    background-color: transparent;

    .link,
    .text,
    p,
    p a {
      color: color(neutral);
    }

    .list__lead-in.text {
      color: color(neutral, 80);
    }
  }

  &--light {
    background-color: color(primary, 10);
    padding-bottom: space(xl);
    padding-top: space(xl);

    @include mq(m) {
      padding-bottom: space(xxl);
      padding-top: space(xxl);
    }

    .link,
    .text,
    p,
    p a {
      color: color(primary);
    }
  }

  // *************** Animation ***************
  // --- HEADER ---
  //  inital animation style & transition
  &[data-animatable-module] &__header {
    opacity: 0;
    position: relative;
    transform: translateY(40px);
    transition: opacity bezier('highlight') time('xxs'),
      transform bezier('highlight') time('xxs');
    // in viewport style
    &.is-in-viewport {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // --- ITEM ---
  &[data-animatable-module] &__item {
    // inital style

    opacity: 0;
    position: relative;
    transform: translateY(20px);
    // animation transition

    transition: opacity bezier('entrance') time('l'),
      transform bezier('entrance') time('l');
    // in viewport style
    &.is-in-viewport {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &[data-animatable-module] &__cta {
    // inital style

    opacity: 0;
    position: relative;
    transform: translateY(10px);

    // animation transition

    transition: opacity bezier('entrance') time('m'),
      transform bezier('entrance') time('m');

    // in viewport style
    &.is-in-viewport {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// *************** Print Styles ***************
@media print {
  .list {
    &__item-icon {
      width: 30pt;
      height: 30pt;
    }
  }
}
