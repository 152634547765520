html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html,
body,
div,
article,
section,
main,
footer,
header,
form,
fieldset,
legend,
pre,
code,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
textarea,
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='url'] {
  box-sizing: border-box;
}

* {
  scroll-behavior: smooth;
}

html {
  // Optimize text rendering speed

  text-rendering: optimizeSpeed;
  // Prevents iOS text size adjust after orientation change, without disabling

  text-size-adjust: 100%;
}

:root {
  @include spacings-to-variables();
  @include animationTokens();
}

body {
  position: relative;
  min-height: 100vh; // to align footer at bottom
  color: color(neutral);
  font-family: #{map-get($fontFamilys, 'default')};
  // enable font smoothing http://szafranek.net/works/articles/font-smoothing-explained/

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scroll-behavior: smooth;

  @include reducedMotionReset();
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

input,
textarea,
select,
button {
  font-family: #{map-get($fontFamilys, 'default')};
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

// Reset default UA Styles for typographical elements
figure {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
}

img[data-src] {
  opacity: 0;
  transition: opacity 400ms;
}

img[src] {
  opacity: 1;
  transition: opacity 400ms;
}

a {
  color: inherit;
  position: relative;
  text-decoration: none;

  @include keyboardFocus(false);
}

// TODO check this
input:is([type='button'], [type='submit'], [type='reset']),
input[type='file']::file-selector-button,
button,
select {
  color: inherit;
}
