$mobileHeight: 50px;
$tabletHeight: 60px;
$desktopHeight: 70px;

.loop {
  @include mq(m) {
    display: flex;
  }

  &__sentence {
    display: block;

    @include mq(m) {
      display: inline-block;
      height: $tabletHeight;
    }

    @include mq(l) {
      height: $desktopHeight;
    }
  }

  &__prefix {
    display: flex;

    @include mq(m) {
      display: inline-flex;
    }
  }

  &__wrapper {
    display: inline-block;
    height: $mobileHeight;
    overflow: hidden;
    position: relative;
    transition: width 500ms ease-out;
    width: 200px;

    @include mq(m) {
      height: $tabletHeight;
    }

    @include mq(l) {
      height: $desktopHeight;
    }
  }

  &__option {
    display: none;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 300ms ease-out;
    word-break: keep-all;
  }

  &__option--active {
    display: inline-block;
  }

  &__option--visible {
    opacity: 1;
  }

  &__option-end {
    display: inline-block;
    height: $mobileHeight;
    white-space: break-spaces;

    @include mq(m) {
      height: $tabletHeight;
    }

    @include mq(l) {
      height: $desktopHeight;
    }
  }

  &__underline {
    background-color: color(accent);
    bottom: 0px;
    height: 4px;
    left: 0;
    position: absolute;
    transition: width 500ms ease-out;
    width: 200px;

    // @include mq(l) {
    //   bottom: -12px;
    // }
  }
}
