/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fonts.com
*/

@font-face {
  font-display: auto;
  font-family: 'calluna';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/62ad54ed-a020-4fc1-a3dc-21e31bf8d52a.woff2')
      format('woff2'),
    url('../fonts/c1b12dfe-db8e-49f0-94ac-7ad0bf217f90.woff') format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'setimo';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Setimo_W_Bd.woff2') format('woff2'),
    url('../fonts/Setimo_W_Bd.woff') format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'setimo';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Setimo_W_Rg.woff2') format('woff2'),
    url('../fonts/Setimo_W_Rg.woff') format('woff');
}
