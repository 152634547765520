.link {
  align-items: center;
  border-bottom: 2px solid color(neutral);
  color: color(neutral);
  display: inline-flex;
  transition: color 200ms ease-in-out, border-bottom-color 200ms ease-in-out;

  &:hover {
    border-bottom-color: color(neutral, 80);
    color: color(neutral, 80);
  }

  &:active,
  &:focus {
    color: color(neutral, 80);
  }

  &--inverted {
    color: color(neutral, 0);
    border-bottom-color: color(neutral, 0);

    &:hover {
      border-bottom-color: color(neutral, 30);
      color: color(neutral, 30);
    }

    &:active,
    &:focus {
      color: color(neutral, 30);
    }
  }

  &:focus-visible {
    border-bottom: 2px solid color(interaction);
    margin-bottom: -2px;
    outline: 0;
  }

  svg {
    height: space(m);
    left: 0;
    margin-left: space('xxs');
    position: relative;
    transition: left 200ms ease-in-out;
    width: space(m);
  }

  &:hover svg {
    left: space(xxs);
  }
}
