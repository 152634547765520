.teaser {
  position: relative;
  z-index: get-z-index('teaser');
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
    transition: color 500ms ease-in-out 200ms,
      background-color 500ms ease-in-out 200ms;
  }

  &__favorite {
    position: absolute;
    margin-top: -4px;
    right: space(m);
    top: space(l);
    z-index: get-z-index('favorite', 'teaser');
  }

  &__favorite:hover + &__container {
    transform: scale(1.025);
  }

  &__container &__link,
  &__container &__footer {
    padding-left: space(m);
    padding-right: space(m);

    &:first-child {
      padding-top: space(l);
    }
  }

  &__link {
    flex-basis: 100%;

    &:last-child {
      padding-bottom: space(l);
    }
  }

  &__footer {
    margin-top: space(s);
    position: relative;
    padding-top: space(s);
    border-top: solid 1px color(neutral, 70);

    &:last-child {
      padding-bottom: space(m);
    }

    label {
      flex-basis: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: space(s);
    margin-right: space(m); // space for favorite btn
  }

  &__copy.text {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  &__headline.text,
  &__copy.text,
  &__details,
  &__containerText.text {
    display: block;
    margin-bottom: space(s);
    margin-top: 0;

    &:last-child {
      margin-bottom: space(xs);
    }
  }

  &__image {
    height: auto;
    margin-bottom: space(s);
    width: 100%;

    img {
      @include scaleImageOnHover();
    }
  }

  &__detail {
    & + & {
      margin-top: space(xs);
    }
  }

  &__detail-icon {
    height: space(s);
    margin-right: space(xs);
    width: space(s);
  }

  // *************** Variations ***************
  &--default &__container {
    text-align: center;

    @include mq(s) {
      padding: 0 space(xxl);
    }

    @include mq(m) {
      padding: 0 space(l);
    }
  }

  &--default &__headline {
    margin-bottom: space(xs);
  }

  &--filled &__container {
    background-color: color(primary, 50);
    color: color(neutral, 0);
  }

  &--light &__container {
    background-color: color(neutral, 10);
    color: color(primary, 70);
  }

  &--filled &__container,
  &--light &__container {
    @include scaleOnHover();
  }

  &--light &__headline.text,
  &--light &__detail-icon {
    transition: color 500ms ease-in-out 200ms, fill 500ms ease-in-out 200ms;
  }

  &--light &__headline.text {
    color: color(primary, 50);
  }

  &--light &__headline.text,
  &--filled &__headline.text {
    padding-bottom: space(m);

    @include mq(m) {
      margin-bottom: auto;
    }
  }

  &--light &__detail-icon {
    fill: color(primary, 50);
  }

  &--light &__footer {
    .checkbox label {
      color: color(primary, 50);
    }
  }

  &--filled &__footer {
    border-top-color: color(primary, 30);

    .checkbox label {
      color: color(neutral, 0);
    }
  }
}
