.teaser-container {
  @include get-grid();

  row-gap: space(xs);

  &__header {
    text-align: center;
    color: color(primary, 80);
    grid-column: 1 / span 4;
    margin-bottom: space(m);
    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
      margin-bottom: space(l);
    }

    @include mq(l) {
      grid-column: 4 / span 6;
    }
  }

  // &__headline.text {
  // }

  &__lead-in.text {
    margin-top: space(xs);
  }

  &__no-results.text {
    color: color(primary, 80);
    grid-column: 1 / span 4;
    margin-bottom: space(m);
    text-align: center;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 1 / span 12;
    }
  }

  &__teaser {
    grid-column: span 4;

    @include mq(s) {
      grid-column: span 6;
    }

    @include mq(m) {
      grid-column: span 6;
    }

    @include mq(l) {
      grid-column: span 4;
    }
  }

  &__link {
    display: flex;
    grid-column: span 4;
    justify-content: center;
    margin-top: space(xs);

    @include mq(s) {
      grid-column: span 6;
    }

    @include mq(m) {
      grid-column: span 12;
      margin-top: space(s);
    }
  }

  &__cta {
    display: none;
    grid-column: 1 / span 4;
    justify-content: center;
    margin-top: space(m);
    text-align: center;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 1 / span 12;
      margin-top: space(l);
    }
  }

  // *************** Variations ***************
  &--default {
    row-gap: space(m);

    @include mq(m) {
      row-gap: space(l);
    }
  }

  &--default &__header {
    margin-bottom: 0;

    @include mq(m) {
      margin-bottom: 0;
    }
  }

  &--default &__headline {
    margin-bottom: space(s);

    @include mq(m) {
      margin-bottom: space(xs);
    }
  }

  &--default &__teaser {
    @include mq(s) {
      margin-bottom: space(s);
    }
    @include mq(m) {
      margin-bottom: 0;
    }
  }

  &--default &__link {
    margin-top: space(xs);

    @include mq(m) {
      margin-top: 0;
    }
  }

  // *************** Hidden Teasers ***************
  &--has-hidden-teasers &__teaser {
    &:nth-of-type(n + 4) {
      display: none;
      @include mq(l) {
        display: block;
      }
    }

    &:nth-of-type(n + 5) {
      @include mq(l) {
        display: none;
      }
    }
  }

  &--has-hidden-teasers &__link {
    display: none;
  }

  &--has-hidden-teasers &__cta {
    display: flex;
  }
}

// *************** Print Styles ***************
@media print {
  .teaser-container {
    display: none;
  }
}
