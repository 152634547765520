.optional-field {
  border: 2px solid color('neutral', 50);
  border-radius: 4px;
  padding: space(m);

  &__cta {
    border-bottom: 2px solid color(neutral);
    color: color(neutral);
    display: inline-flex;
    align-items: center;
    transition: color 200ms ease-in-out, border-bottom-color 200ms ease-in-out;

    &:hover {
      border-bottom-color: color(neutral, 80);
      color: color(neutral, 80);
    }
  }

  &__details[open] &__cta-icon {
    transform: rotate(180deg);
  }

  &__icon {
    margin-right: space(xs);
  }

  &__section {
    margin-top: space(m);
  }

  &__copy.text {
    margin-top: space(s);
  }
}
