.checkbox {
  display: block;
  width: 100%;

  &__wrapper {
    align-items: center;
    display: flex;
  }

  // Checkbox Label
  label {
    align-items: center;
    color: color('neutral');
    cursor: pointer;
    display: flex;
    hyphens: auto;
    position: relative;

    a {
      color: color(neutral);
      text-decoration: underline;

      @include mouseHoverState();
    }
  }

  &__required {
    color: color('neutral');
    line-height: 1;
  }

  input:disabled ~ label {
    color: color(neutral, 70);
    cursor: not-allowed;
    opacity: 1;
  }

  // Checkbox Native Input
  input {
    @include hide-visually();
  }

  // Checkbox Custom Input
  &__custom-checkbox {
    // margin-top: 2px; // to align with label
    align-items: center;
    background-color: color(neutral, 0);
    border: 2px solid color(neutral, 70);
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    height: space(s);
    justify-content: center;
    margin-right: space('xs');
    position: relative;
    transition: color 200ms, background-color 200ms, border-color 200ms;
    width: space(s);

    svg {
      display: none;
      fill: color(neutral, 0);
      height: 100%;
      width: 100%;
    }
  }

  input:disabled ~ label &__custom-checkbox {
    border-color: color(neutral, 50);
    cursor: not-allowed;
    opacity: 1;
  }

  input:checked ~ label &__custom-checkbox {
    background-color: color(neutral, 100);
    border-color: color(neutral, 100);
  }

  &--light input:checked ~ label &__custom-checkbox {
    background-color: color(neutral, 70);
    border-color: color(neutral, 70);
  }

  input:checked ~ label &__custom-checkbox svg {
    display: block;
  }

  &.form-error &__custom-checkbox {
    border-color: color(warning);
    color: color(warning);
  }

  input:focus-visible ~ label &__custom-checkbox {
    border-color: color(interaction);
  }

  // &--success &__custom-checkbox {
  //   border-color: color(success);
  //   color: color(success);
  // }

  // Error Message
  &__error.text {
    color: color(warning);
    display: none;
    margin-top: space('xxs');
  }

  &.form-error &__error {
    display: block;
  }

  // Hint Message
  &__hint.text {
    color: color(neutral, 80);
    display: block;
    margin-top: space('xxs');
  }
}
