.location {
  background-color: color(primary);
  color: color(neutral, 10);
  overflow: hidden;
  padding: space(l) 0;
  position: relative;
  z-index: get-z-index('location');

  @include mq(s) {
    min-height: 50vh;
  }

  @include mq(m) {
    min-height: 600px;
  }

  &__app {
    @include max-width-container;
    @include get-grid;
    min-height: inherit;
  }

  &__content {
    grid-column: 1 / span 4;
    grid-row: 1 / span 1;
    margin: space(s) 0;
    z-index: get-z-index('content', 'location');

    @include mq(s) {
      grid-column: 1 / span 6;
      grid-row: 1 / span 1;
      margin: space(l) 0;
      max-width: 45%;
    }

    @include mq(m) {
      grid-column: 1 / span 12;
      max-width: 40%;
      padding-left: space(l);
    }

    @include mq(l) {
      max-width: 30%;
    }
  }

  // &__headline.text {
  // }

  &__subline.text {
    margin-top: space(m);
    margin-bottom: space(s);

    @include mq(s) {
      margin-top: space(l);
    }
  }

  &__details {
    display: flex;
    flex-direction: column;

    a {
      @include mouseHoverState(0.8);
    }
  }

  &__detail {
    align-items: center;
    display: flex;

    &:not(:first-child) {
      margin-top: space(xs);
    }
  }

  &__detail-icon {
    margin-right: space(xs);
  }

  &__detail-link,
  &__detail-link:hover,
  &__detail-link:active,
  &__detail-link:focus {
    border-bottom-color: color(neutral, 0);
    color: color(neutral, 0);
    margin-top: space(s);
  }

  &__btn {
    color: color(neutral, 0);
    margin-top: 0;

    @include mq(s) {
      margin-top: space(l);
    }

    .button__icon-wrapper {
      margin-left: 0;
      margin-right: space(xs);
      order: -1;
    }
  }

  &__back-btn {
    color: color(neutral, 0);
  }

  &__search {
    margin-bottom: space(xs);
    position: relative;

    @include mq(m) {
      display: flex;
      margin-bottom: 0;
    }
  }

  &__search-input {
    margin-bottom: space(xs);
    position: relative;

    @include mq(m) {
      margin-bottom: 0;
    }

    .autosuggest input[type='text'] {
      @include mq(m) {
        border-radius: 0;
        border-bottom-left-radius: space(xxs);
        border-top-left-radius: space(xxs);
      }
    }

    .react-autosuggest__suggestion {
      transition: background-color 200ms ease-in-out;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: color('neutral', 30);
    }

    // should be active
    .react-autosuggest__suggestion--first {
      background-color: color('neutral', 30);
    }

    input[aria-activedescendant]
      + .react-autosuggest__suggestions-container
      .react-autosuggest__suggestion--first:not(:hover) {
      background-color: color('neutral', 10);
    }
  }

  &__search-icon {
    fill: color(neutral, 70) !important;
    left: space(xs);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include mq(m) {
      left: space(s);
    }
  }

  &__search-btn {
    border: 0 none;
    flex-shrink: 0;
    width: 100%;

    &[disabled] {
      background-color: color(accent);
      border-color: color(accent);
      color: color(neutral);
    }

    @include mq(m) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      width: auto;
    }
  }

  &__search-error.text {
    margin-top: space(xs);
  }

  &__map {
    grid-column: 1 / span 4;
    height: 150vw;
    margin: 0 calc(-1 * #{get-grid-outer-gap('default')});
    position: relative;

    @include mq(s) {
      height: 100%;
      left: 0;
      margin: auto;
      min-height: auto;
      position: absolute;
      top: 0;
      transform: translateX(25%);
      width: 100%;
    }

    @include mq(m) {
      max-width: 2200px;
      transform: translateX(15%);
    }

    &::before {
      background: linear-gradient(to top, transparent 0%, #314e52 90%);
      content: '';
      height: 10%;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: get-z-index('gradient', 'location');

      @include mq(s) {
        background: linear-gradient(290deg, transparent 50%, #314e52 100%);
        bottom: 0;
        height: auto;
        right: auto;
        width: 50%;
      }

      @include mq(l) {
        background: linear-gradient(295deg, transparent 30%, #314e52 100%);
        width: 40%;
        max-width: 600px;
      }
    }

    &::after {
      content: '';
      pointer-events: none;

      @include mq(s) {
        background: linear-gradient(to right, transparent 0%, #314e52 100%);
        bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 20%;
        z-index: get-z-index('gradient', 'location');
      }
    }
  }

  &__google-map {
    height: 100%;
    width: 100%;

    // overwrite google maps styles
    & > * {
      background-color: transparent !important;
    }

    .gm-style .gmnoprint.gm-style-cc > *:first-child,
    .gm-style .gmnoprint .gm-style-cc > *:first-child {
      opacity: 0.5 !important;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    transform: translateY(-30px);

    &--hidden {
      display: none !important;
    }

    &--is-active {
      display: block !important;
      transform: translateY(-55px);
    }
  }

  &__overlay__content {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    background-color: color(accent);
    padding: 0 space(xs);
    border-radius: space(xs);
    width: max-content;

    color: color(neutral, 90);
    @include typography(copy-small);
    font-weight: bold;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 9px 8.5px 0 8.5px;
      border-color: color(accent) transparent transparent transparent;
      margin-top: -1px;
      transform: translate(-8px, calc(100% - 1px));
    }
  }

  &__consent {
    grid-column: 1 / span 4;
    grid-row: 1 / span 2;
    z-index: get-z-index('consent', 'location');
    background-color: color(neutral, 10);
    color: color(neutral);
    padding: space(s);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 1 / span 12;
      padding: space(l) space(xl);
    }
  }

  &__consent-headline.text {
    text-align: center;
    margin-bottom: space(s);
  }
  &__consent-copy.text {
    text-align: center;
    margin-bottom: space(m);
  }

  &__consent-ctas {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__consent-cta {
    margin: space(xxs);
    @include mq(s) {
      margin: space(xs);
    }
  }
}

// *************** Print Styles ***************
@media print {
  .location {
    button,
    &__search {
      display: none;
    }
  }
}
