.blocks {
  margin-bottom: space(xl);
  width: 100%;

  @include mq(m) {
    margin-bottom: space(xxl);
  }

  &__block--has-grid-container {
    @include max-width-container;
  }

  &__block,
  &__block--has-grid-container {
    margin-bottom: space(l);
    margin-top: space(l);

    @include mq(m) {
      margin-bottom: space(xxl);
      margin-top: space(xxl);
    }
  }

  &__block[data-type='report'] {
    margin-top: 0;

    @include mq(m) {
      margin-top: 0;
    }
  }
}

// *************** Print Styles ***************
@media print {
  .blocks {
    margin-bottom: 0pt;

    &__block {
      margin-bottom: 15pt;
      margin-top: 15pt;
    }
  }
}
