.video {
  margin-bottom: space(s);
  position: relative;
  z-index: get-z-index('video');

  @media print {
    display: none;
  }

  &__poster {
    left: 0;
    position: absolute;
    top: 0;

    &-image {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__embed_container,
  &__poster,
  &__button {
    background: color('neutral', 10);
    padding-top: 56.26%;
    width: 100%;
  }

  &__iframe,
  &__player {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__embed_container {
    position: relative;
    z-index: get-z-index('embed-container', 'video');
  }

  &__button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    left: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    top: 0;
    z-index: get-z-index('button', 'video');

    &-icon {
      height: 90px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform 250ms cubic-bezier(0.14, 0.58, 0.375, 0.895);
      width: 90px;

      &-circle {
        fill: black; // fallback
        transition: fill 250ms cubic-bezier(0.14, 0.58, 0.375, 0.895);
      }

      &-arrow {
        fill: white;
      }
    }
  }

  &--is-playing &__button,
  &--is-playing &__poster {
    display: none;
  }

  &__button:hover &__button-icon {
    transform: translate(-50%, -50%) scale(1.1);
  }

  &__button:hover &__button-icon circle {
    fill: red;
  }

  &__caption {
    &-headline {
      text-transform: uppercase;
    }
  }

  &__consent-missing {
    display: none;
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 0;
    height: 100%;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: color('neutral', 10);
    padding: space(s);

    @include mq(s) {
      padding: space(m);
    }

    @include mq(m) {
      padding: space(l);
    }
  }

  &.consent-permission-missing &__consent-missing {
    display: flex;
  }

  &__consent-icon {
    display: none;

    @include mq(s) {
      display: block;
      width: space(xl);
      height: space(xl);
    }

    @include mq(m) {
      width: space(xxl);
      height: space(xxl);
    }
  }

  &__consent-headline,
  &__consent-copy {
    margin-top: space(xs);
    max-width: 600px;

    @include mq(s) {
      margin-top: space(s);
    }
  }

  &__consent-ctas {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: space(xs);

    @include mq(s) {
      margin-top: space(m);
    }
  }

  &__consent-details-btn,
  &__consent-btn.button {
    margin: space(xxs);
    @include mq(s) {
      margin: space(xs);
    }
  }
}
