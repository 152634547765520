.chart {
  position: relative;
  width: 99%; // for some reason width:100% would cause grid resizing issues.

  &__canvas-wrapper {
    height: min-content;
  }

  #chart__html-legend {
    display: flex;
    flex-direction: column;

    ul {
      margin-bottom: space(s);
      display: flex;
      flex-wrap: wrap;
      order: -1;
    }

    li {
      align-items: center;
      cursor: pointer;
      display: flex;
      margin-left: space(s);
      margin-bottom: space(xs);
      color: color(primary, 20);
    }

    .checkbox__custom-checkbox {
      border: none;
    }
  }

  &__legend-categories {
    display: flex;
    flex-wrap: wrap;
    order: 2;

    div {
      margin-left: space(s);
      margin-bottom: space(xs);
      color: rgb(102, 102, 102);
    }
  }

  &__legend-items {
    display: none;
  }
}
