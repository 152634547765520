.favorite-btn {
  position: relative;
  flex-shrink: 0;
  background-color: transparent;
  border: 0 none;
  outline: none;
  padding: space(xxs);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus-visible {
    border-color: color(interaction);
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-bottom: 2px;
  }

  &__tooltip {
    opacity: 0;
    position: absolute;
    transform: translateY(calc(-100% - 3px));
    top: 0;
    width: max-content;
    padding: 0 space(xs);
    border-radius: space(xs);
    transition: opacity 200ms ease-in-out;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: rotate(360deg) translateY(98%) translateX(-50%);
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 9px 8.5px 0 8.5px;
      border-top-color: color(neutral, 0);
    }
  }

  &:hover &__tooltip {
    opacity: 1;
  }

  &__icon-add,
  &__tooltip-add {
    display: block;
  }

  &__icon-remove,
  &__tooltip-remove {
    display: none;
  }

  &--active &__icon-add,
  &--active &__tooltip-add {
    display: none;
  }

  &--active &__icon-remove,
  &--active &__tooltip-remove {
    display: block;
  }

  // *************** Variations ***************
  &--default,
  &--light {
    color: color(primary);
  }

  &--filled {
    color: color(neutral, 0);
  }

  &--default &__tooltip,
  &--light &__tooltip {
    background-color: color(primary);
    color: color(neutral, 0);
  }

  &--default &__tooltip:after,
  &--light &__tooltip:after {
    border-top-color: color(primary);
  }

  &--filled &__tooltip {
    background-color: color(neutral, 0);
    color: color(primary);
  }

  &--filled &__tooltip:after {
    border-top-color: color(neutral, 0);
  }

  &--button {
    border: 3px solid color(accent);
    border-radius: 6px;
    padding: space(xs) space(s);
    * {
      transition: color 200ms, fill 200ms, border-color 200ms;
    }

    .favorite-btn__tooltip {
      display: block;
      background-color: unset;
      color: black;
      position: relative;
      opacity: 1;
      transform: unset;
      order: 1;
      padding-right: 0;
      border-radius: 0;

      &::after {
        content: unset;
      }
    }

    &:hover * {
      color: color(neutral, 70);
      fill: color(neutral, 70);
    }
    &:hover {
      border-color: color(accent, 70);
    }
  }

  &--small {
    padding: space(xxs) space(m);
  }
}
