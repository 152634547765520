.organisationAnalysis {
  @include max-width-container;
  @include get-grid-outer-gaps;
  padding-top: space(xl);
  padding-bottom: space(xl);

  --chart-overview-height: 160px;
  &__form {
    margin-bottom: space(l);
  }

  &__chartsOverview {
    height: var(--chart-overview-height);
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    padding: space(xs) space(m);
    transition: all 0.5s ease;
  }
  &__chart-wrapper {
    flex-basis: 100%;
    @include mq(m) {
      flex-basis: 33%;
    }
    .text {
      height: 2rem;
    }
    .chart__canvas-wrapper {
      height: 100px;
    }
    &__text {
      display: flex;
      gap: space(xxs);
    }
    .info-point {
      margin-top: 2px;
    }
  }

  .drawer__section__title {
    top: var(--chart-overview-height);
  }
  .drawer__section__content {
    display: flex;
    flex-wrap: wrap;

    .organisationAnalysis__chart-wrapper:nth-of-type(3n) {
      .text,
      .info-point {
        opacity: 0;
      }
    }
    .organisationAnalysis__chart-wrapper:nth-of-type(3n - 1) {
      .text,
      .info-point {
        opacity: 0;
      }
    }
  }

  &__bestAndWorst {
    margin: space(xs) space(m) space(l) space(m);
  }
}
