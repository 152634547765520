@mixin max-width-container($width: 'default') {
  margin: 0 auto;
  max-width: get-max-width($width);
  width: 100%;
}

@function get-grid-outer-gap($breakpoint) {
  $breakpointMap: map-get($gridConfigs, $breakpoint);
  $outerGap: map-get($breakpointMap, 'outerGap');

  @return $outerGap;
}

@mixin get-grid-outer-gaps {
  @each $breakpoint in map-keys($gridConfigs) {
    $breakpointMap: map-get($gridConfigs, $breakpoint);
    $outerGap: map-get($breakpointMap, 'outerGap');

    @if $breakpoint == 'default' {
      padding-left: $outerGap;
      padding-right: $outerGap;
    } @else {
      @include mq($breakpoint) {
        padding-left: $outerGap;
        padding-right: $outerGap;
      }
    }
  }
}

@mixin get-grid-columns($columnsConfig) {
  @if $columnsConfig != () {
    @each $breakpoint in map-keys($columnsConfig) {
      $columns: map-get($columnsConfig, $breakpoint);

      @if $breakpoint == 'default' {
        grid-template-columns: repeat($columns, 1fr);
      } @else {
        @include mq($breakpoint) {
          grid-template-columns: repeat($columns, 1fr);
        }
      }
    }
  } @else {
    @each $breakpoint in map-keys($gridConfigs) {
      $breakpointMap: map-get($gridConfigs, $breakpoint);
      $columns: map-get($breakpointMap, 'columns');

      @if $breakpoint == 'default' {
        grid-template-columns: repeat($columns, 1fr);
      } @else {
        @include mq($breakpoint) {
          grid-template-columns: repeat($columns, 1fr);
        }
      }
    }
  }
}

@mixin get-grid-gap {
  @each $breakpoint in map-keys($gridConfigs) {
    $breakpointMap: map-get($gridConfigs, $breakpoint);
    $columnGap: map-get($breakpointMap, 'columnGap');

    @if $breakpoint == 'default' {
      grid-column-gap: $columnGap;
    } @else {
      @include mq($breakpoint) {
        grid-column-gap: $columnGap;
      }
    }
  }
}

@mixin get-grid($columnsConfig: (), $outerGridGaps: true) {
  display: grid;
  @include get-grid-columns($columnsConfig);
  @include get-grid-gap;

  @if $outerGridGaps {
    @include get-grid-outer-gaps;
  }
}
