.textarea {
  // Textarea Label
  label {
    color: color('neutral');
    display: block;
    font-weight: bold;
    margin-bottom: space('xxs');
  }

  &--hidden-label label {
    @include hide-visually();
  }

  // Native Textarea
  textarea {
    background: color(neutral, 0);
    border: 2px solid color(neutral, 70);
    border-radius: 0;
    color: color(neutral);
    outline: none;
    padding: space(xxs) space(xs);
    position: relative;
    resize: none;
    transition: color 200ms, background-color 200ms, border-color 200ms;
    width: 100%;
  }

  textarea::placeholder {
    color: color(neutral, 70);
    opacity: 1;
  }

  textarea,
  textarea::placeholder {
    @include typography(copy);
  }

  &.form-error textarea {
    border-color: color(warning);
  }

  textarea:hover {
    border-color: color(neutral, 80);
  }

  textarea:disabled {
    border-color: color(neutral, 50);

    &::placeholder {
      color: color(neutral, 50);
    }
  }

  [data-whatinput='keyboard'] & textarea:focus {
    border-color: color(interaction);
  }

  // &--success textarea {
  //   border-color: color(success);
  // }

  // Error Message
  &__error {
    color: color(warning);
    display: none;
    margin-top: space('xxs');
  }

  &.form-error &__error {
    display: block;
  }

  // Hint Message
  &__hint.text {
    color: color(neutral, 80);
    display: block;
    margin-top: space('xxs');
  }
}
