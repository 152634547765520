/* stylelint-disable aditayvm/at-rule-no-children */
// Default print styling from https://html5boilerplate.com/

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important; /* Black prints faster:
                                 http://www.sanbeiji.com/archives/953 */
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */

  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  // p {
  //   font-size: 12px !important;
  //   margin-bottom: 18px !important;
  // }

  // h2,
  // h3 {
  //   margin-bottom: 24px !important;
  // }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    page-break-after: avoid;
  }

  h1 {
    page-break-before: always;
  }

  table,
  figure,
  canvas {
    page-break-inside: avoid;
  }
}

@page {
  margin: 1cm 1cm;
}
