.info-point {
  display: inline-block;
  width: max-content;
  height: max-content;

  &__icon {
    cursor: pointer;
  }

  #arrow,
  #arrow::before {
    position: absolute;
    width: 18px;
    height: 18px;
    background: inherit;
  }
  #arrow {
    visibility: hidden;
  }
  #arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &__popover {
    display: none;
    background: color(primary);
    color: white;
    padding: space(m) space(s);
    border-radius: 10px;
    font-size: 14px;
    max-width: 305px;
    z-index: 999;

    h1,
    h2,
    h3,
    h4 {
      margin-bottom: space(xs);
      font-weight: bold;
    }
  }
  &__popover[data-show] {
    display: block;
  }
  &__popover[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
  }
  &__popover[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
  }
  &__popover[data-popper-placement^='left'] > #arrow {
    right: -4px;
  }
  &__popover[data-popper-placement^='right'] > #arrow {
    left: -4px;
  }
}
