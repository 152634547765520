[style*='--ratio'] {
  aspect-ratio: var(--ratio);
  box-sizing: border-box;
  overflow: hidden; // image scaling on hover
  position: relative;

  > * {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
