.screen {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: inherit;

  &__intro {
    @include max-width-container;
    @include get-grid;

    padding-bottom: space(m);
    padding-top: space(l);

    @include mq(s) {
      padding-bottom: space(l);
      padding-top: space(xl);
    }
  }

  &__intro-content {
    grid-column: span 4;

    @include mq(s) {
      padding-right: space(m);
      // padding-top: space(m);
    }

    @include mq(m) {
      grid-column: 3 / span 6;
      padding-right: space(l);
    }
  }

  // &__intro-image {
  // }
  &__intro-image--portrait {
    grid-column: span 2;
    padding-top: space(m);

    @include mq(s) {
      padding-top: 0;
    }
  }

  &__intro-back {
    margin-bottom: space(s);

    @include mq(s) {
      margin-bottom: space(m);
    }

    svg {
      margin-left: 0;
      margin-right: space(xs);
      order: -1;
    }
  }

  &__intro-overline + &__intro-headline {
    padding-top: space(xs);
  }

  &__intro-headline:not(:last-child) {
    padding-bottom: space(s);

    @include mq(m) {
      padding-bottom: space(m);
    }
  }
}
