.debug-grid {
  @include max-width-container;

  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.1s ease-in-out;
  z-index: 999999;

  &--visible {
    outline-color: var(--color-interaction);
    outline-style: solid;
    outline-width: 1px;
  }

  &__inner {
    opacity: 0;
  }

  &--visible &__inner {
    opacity: 1;
  }

  &__default {
    @include get-grid;

    height: 100vh;
  }

  &__column {
    outline-color: var(--color-accent);
    outline-style: solid;
    outline-width: 1px;
  }

  &--storybook &__column {
    background: #5b595422;
  }
}
