.sticky {
  background-color: color(neutral, 10);
  border-top-left-radius: space(xxs);
  border-top-right-radius: space(xxs);
  bottom: 0;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  left: 0;
  padding: space(xs) 0;
  position: fixed;
  right: 0;
  transition: height 500ms ease-in-out;
  z-index: get-z-index('sticky');

  @include mq(m) {
    bottom: 0;
    left: auto;
    padding: space(s) space(m);
    right: 30px;
  }

  &__cta {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 0 get-grid-outer-gap('s');

    @include mq(m) {
      width: auto;
      flex-direction: column;
      padding: 0;
    }
  }

  &__cta-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    margin-right: space(s);

    @include mq(m) {
      flex-direction: column;
      justify-content: center;
      margin-bottom: space(xs);
      text-align: center;
      margin-right: 0;
    }
  }

  &__cta-image {
    margin-right: space(xs);
    transition: margin-top 500ms ease-in-out;
    flex-shrink: 0;

    @include mq(m) {
      margin-bottom: space(xs);
      margin-right: 0;
      margin-top: calc(-1 * (var(--spacings-s) + 60px / 2));
    }

    img {
      border-radius: 50%;
      height: 54px;
      transition: height 500ms ease-in-out, width 500ms ease-in-out;
      width: 54px;

      @include mq(m) {
        height: 60px;
        width: 60px;
      }
    }
  }

  &__cta-image--large {
    @include mq(m) {
      margin-top: calc(-1 * (var(--spacings-s) + 80px / 2));
    }

    img {
      @include mq(m) {
        height: 80px;
        width: 80px;
      }
    }
  }

  // &__cta-title.text {}

  &__cta-hidden-wrapper {
    @include mq(m) {
      width: 280px;
    }
  }

  &__cta-hidden {
    @include mq(m) {
      padding-bottom: space(xs);
    }
  }

  &__cta-detail {
    align-items: center;
    justify-content: center;
    display: flex;

    &:not(:first-child) {
      margin-top: space(xs);
    }
  }

  &__cta-detail-icon {
    margin-right: space(xs);
  }

  &__detail-phone {
    display: none;

    @include mq(m) {
      display: block;
    }
  }

  &__cta-hidden {
    opacity: 0;
    // hide initially

    position: absolute;
    text-align: center;
    transition: opacity 300ms ease-in-out;
    visibility: hidden;
  }

  &--active &__cta-hidden {
    opacity: 1;
    position: static;
    visibility: visible;
  }

  &__cta-copy.text {
    margin-bottom: space(xs);
  }

  &__cta-mobile-buttons {
    display: inline-flex;
    flex-shrink: 0;

    @include mq(m) {
      display: none;
    }
  }

  &__cta-mobile-button:not(:first-child) {
    margin-left: space(xs);
  }

  &__cta-button {
    display: none;

    @include mq(m) {
      display: block;
      margin-top: auto;
      padding: space(xxs) space(xl);
    }

    @include mq(m) {
      padding: space(xxs) space(l);
    }
  }
}

// *************** Print Styles ***************
@media print {
  .sticky {
    display: none;
  }
}
