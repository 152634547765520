.compass {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: inherit;
  background-color: color('primary', 90);

  /* ---------- Background ---------- */
  &__background {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    aspect-ratio: 2.5/1; // min-height equals 16/10
    overflow: hidden;
  }

  &__background-inner {
    @include max-width-container('images');

    height: 100%;
    width: 200%;
    left: -75%;
    overflow: hidden;
    position: relative;

    @include mq(s) {
      width: 120%;
      left: auto;
    }

    @include mq(m) {
      width: 100%;
    }

    &:after {
      background: linear-gradient(
        to bottom,
        rgba(34, 54, 57, 0) 2%,
        rgba(34, 54, 57, 0.96) 85%,
        #223639 90%,
        #1a2a2d 95%
      );
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  /* ---------- Main ---------- */

  &__main {
    @include max-width-container;
    @include get-grid;
  }

  &__wrapper {
    position: relative;
    background-color: color('neutral', 10);
    grid-column: span 6;
    padding: 0 space(m);
    margin: space(m) 0;
    border-radius: space(xxs);

    @include mq(m) {
      grid-column: 1 / span 12;
      padding: 0 space(l);
      margin: space(l) 0;
    }
  }

  &__block {
    margin-bottom: space(m);
    margin-top: space(m);

    @include mq(m) {
      margin-bottom: space(l);
      margin-top: space(l);
    }
  }

  &__block[data-type='text'] {
    max-width: 780px;
  }
}
