.input-group {
  position: relative;

  &--saved {
    background: color(neutral, 10);
    padding: space(m);

    .multistep-form__field,
    .input-group__save-btn {
      display: none;
    }

    .input-group__input-display,
    .input-group__edit-btn {
      display: block;
    }
  }

  &--unsaved {
    padding: space(m) 0;
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin: space(m) 0;
  }

  &__input-display {
    display: none;
    margin-bottom: space(m);
  }

  &__edit-btn {
    display: none;

    position: absolute;
    top: space(m);
    right: space(m);
    border: none;
    width: min-content;
    .text {
      @include typography(copy-small);
    }
    padding: 0;
    background-color: color(neutral, 10);
    border-radius: 0;
    border-bottom: 2px solid color(accent, 50);
  }
}
